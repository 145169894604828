<template>
  <div class="m-4">
    <TabMenu :model="menuItems" />
    <router-view />
  </div>
</template>

<script>
import TabMenu from "primevue/tabmenu";

export default {
  components: {
    TabMenu,
  },
  data() {
    return {
      menuItems: [
        {
          label: "Integrations",
          to: "/admin/integrations",
        },
        {
          label: "Settings",
          to: "/admin/settings",
        },
      ],
    };
  },
};
</script>
