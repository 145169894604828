import axios from "axios";

export default {
  state() {},
  actions: {
    async updateContact(context, payload) {
      await axios.put(
        `${process.env.VUE_APP_API_URL}/contacts/${payload.contactId}`,
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          phone: payload.phone,
          email: payload.email,
        },
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
    },
  },
};
