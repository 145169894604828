import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Showings from "../views/Showings.vue";
import Admin from "../views/Admin.vue";
import Forbidden from "../views/Errors/Forbidden.vue";
import store from "../store/store";

const checkAdmin = (to, from, next) => {
  if (!store.getters.isAdmin) {
    next("/forbidden");
  } else {
    next();
  }
};

const checkManagement = async (to, from, next) => {
  if (store.getters.isManagement || store.getters.isAdmin) {
    next();
  } else {
    next("/forbidden");
  }
};

const today = `${new Date().toISOString().slice(0, 10)}`;

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/showings/:date",
    name: "Showings",
    component: Showings,
  },
  {
    path: "/showings",
    redirect: {
      name: "Showings",
      params: {
        date: today,
      },
    },
  },
  {
    path: "/conversations",
    name: "Conversations",
    component: () => import("../views/Conversations.vue"),
    children: [
      {
        path: "/conversations/inbox",
        name: "SMS Inbox",
        component: () => import("../views/conversations/Inbox.vue"),
      },
      {
        path: "/conversations/inbox/:id",
        name: "ContactConversation",
        component: () => import("../views/conversations/ContactConversation.vue"),
      },
      {
        path: "/conversations/templates",
        name: "User Templates",
        component: () => import("../views/conversations/UserTemplates.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    beforeEnter: checkAdmin,
    component: Admin,
    children: [],
  },
  {
    path: "/management",
    name: "Management",
    beforeEnter: checkManagement,
    component: () => import("../views/Management.vue"),
    children: [],
  },
  {
    path: "/maps",
    name: "Maps",
    component: () => import("../views/Maps.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    children: [
      {
        path: "/settings/user/signature",
        name: "UserSignature",
        component: () => import("../views/settings/UserSignature.vue"),
      },
      {
        path: "/settings/integrations",
        component: () => import("../views/admin/Integrations.vue"),
        children: [
          {
            path: "/settings/integrations/:appName",
            component: () => import("../views/admin/IntegrationSettings.vue"),
          },
        ],
      },
      {
        path: "/settings/server",
        component: () => import("../views/admin/Settings.vue"),
      },
      {
        path: "/settings/users",
        component: () => import("../views/management/Users.vue"),
      },
      {
        path: "/settings/templates",
        name: "Templates",
        component: () => import("../views/management/Templates.vue"),
        children: [
          {
            path: "/settings/templates/sms",
            name: "SMS",
            component: () => import("../views/management/templates/SMS.vue"),
            children: [
              {
                path: "/settings/templates/sms/:id",
                name: "SMSEdit",
                component: () => import("../components/templates/EditSMS.vue"),
                meta: { templatePath: true },
              },
            ],
          },
        ],
      },
      {
        path: "/settings/property-groups",
        name: "PropertyGroups",
        component: () => import("../views/management/PropertyGroups.vue"),
      },
      {
        path: "/settings/properties",
        name: "Properties",
        component: () => import("../views/management/Properties.vue"),
      },
      {
        path: "/settings/tenants",
        name: "Tenants",
        component: () => import("../views/management/Tenants.vue"),
      },
      {
        path: "/settings/scorecards",
        name: "ScorecardSettings",
        component: () => import("../views/management/Scorecards.vue"),
      },
      {
        path: "/settings/knowledge%20base",
        name: "Categories",
        component: () => import("../views/settings/knowledge_base/KnowledgeBase.vue"),
        children: [
          {
            path: "/settings/knowledge%20base/category/:id",
            name: "Category",
            component: () => import("../views/settings/knowledge_base/Category.vue"),
          },
          {
            path: "/settings/knowledge%20base/article/:id",
            name: "Article",
            component: () => import("../views/settings/knowledge_base/Article.vue"),
          },
        ],
      },
      {
        path: "/settings/knowledge%20base/upload",
        name: "KnowledgeBaseUpload",
        component: () => import("../views/settings/knowledge_base/BulkUpload.vue"),
      },
      {
        path: "/settings/jobs",
        name: "Jobs",
        component: () => import("../views/admin/Jobs.vue"),
      },
    ],
  },
  {
    path: "/company-directory",
    name: "CompanyDirectory",
    component: () => import("../views/CompanyDirectory.vue"),
  },
  {
    path: "/knowledge",
    name: "Knowledge Base",
    component: () => import("../views/Knowledge.vue"),
  },
  {
    path: "/knowledge/handbook",
    name: "Employee Handbook",
    component: () => import("../views/policies/Handbook.vue"),
  },
  {
    path: "/knowledge/policyandprocedures",
    name: "Policy and Procedures",
    component: () => import("../views/policies/MaxxPolicies.vue"),
  },
  {
    path: "/knowledge/azpolicyandprocedures",
    name: "AZ Policy and Procedures",
    component: () => import("../views/policies/AZPolicies.vue"),
  },
  {
    path: "/knowledge/landlordtenantact",
    name: "Landlord Tenant Act",
    component: () => import("../views/policies/LandlordTenantAct.vue"),
  },
  {
    path: "/knowledge/article/:slug",
    name: "Knowledge Base Article",
    component: () => import("../views/Article.vue"),
  },
  {
    path: "/scorecards",
    name: "Scorecards",
    component: () => import("../views/scorecards/Scorecards.vue"),
  },
  {
    path: "/scorecards/:id",
    name: "Scorecard",
    component: () => import("../views/scorecards/Scorecard.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: Forbidden,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
