<template>
  <MDBContainer tag="form" @submit.prevent="onNewShowing">
    <p>
      {{ newShowingDateTime }}
    </p>
    <Button
      class="p-button-text p-link"
      icon="pi pi-angle-left"
      label="Back"
      @click="back"
      v-if="!prevDay"
    />
    <MDBRow class="gx-2 mt-4">
      <MDBCol>
        <span class="p-float-label">
          <Dropdown
            :options="units"
            optionLabel="unit_name"
            optionValue="id"
            id="location"
            v-model="newShowing.unitId"
            filter
            :loading="loadingUnits"
          />
          <label for="location">Unit</label>
        </span>
      </MDBCol>
    </MDBRow>
    <MDBRow class="gx-2 mt-4">
      <MDBCol>
        <span class="p-float-label">
          <InputText id="firstName" v-model="newShowing.firstName" />
          <label for="firstName">First Name</label>
        </span>
      </MDBCol>
      <MDBCol>
        <span class="p-float-label">
          <InputText id="lastName" v-model="newShowing.lastName" />
          <label for="lastName">Last Name</label>
        </span>
      </MDBCol>
    </MDBRow>
    <MDBRow class="gx-2 mt-4">
      <MDBCol>
        <span class="p-float-label">
          <InputText id="email" v-model="newShowing.email" />
          <label for="email">Email</label>
        </span>
      </MDBCol>
      <MDBCol>
        <span class="p-float-label">
          <InputText id="phoneNumber" v-model="newShowing.phone" />
          <label for="phoneNumber">Phone Number</label>
        </span>
      </MDBCol>
    </MDBRow>
    <div class="d-flex justify-content-end">
      <div v-if="!isLoading" class="d-flex justify-content-end mt-4">
        <Button type="submit" label="Create" />
        <Button
          label="Cancel"
          class="p-button-outlined p-button-secondary ms-2"
          @click="cancelNewWalkIn"
        />
      </div>
      <MDBSpinner class="m-2" v-else />
    </div>
  </MDBContainer>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { MDBContainer, MDBCol, MDBRow, MDBSpinner } from "mdb-vue-ui-kit";
import Button from "primevue/button";
import { Showing, InvalidShowing } from "../../services/showings";
import { showingUnits } from "../../services/units";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

//eslint-disable-next-line
const props = defineProps([
  "newShowingDateTime",
  "prevDay",
  "start",
  "end",
  "time",
  "isWalkIn",
]);
//eslint-disable-next-line
const emit = defineEmits(["back", "cancel", "created"]);

const newShowing = ref({
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  unitId: null,
  time: null,
  walkIn: false,
  start: null,
  end: null,
});
const isLoading = ref(false);
const units = computed(() => showingUnits.units);
const loadingUnits = ref(true);

onMounted(async () => {
  await showingUnits.loadUnits();
  loadingUnits.value = false;
  newShowing.value.start = props.start;
  newShowing.value.end = props.end;
  newShowing.value.time = props.time;
  newShowing.value.walkIn = props.isWalkIn;
});

async function onNewShowing() {
  isLoading.value = true;
  try {
    await Showing.create(newShowing.value);
    emit("created");
  } catch (error) {
    if (error instanceof InvalidShowing) {
      error.invalidFields.forEach((field) => {
        const el = document.querySelector(`#${field.id}`);
        el.classList.add("p-invalid");
      });
    }
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}

function back() {
  newShowing.value.time = null;
  emit("back");
}

function cancelNewWalkIn() {
  newShowing.value = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    unitId: null,
    time: null,
    walkIn: false,
    start: null,
    end: null,
  };
  emit("cancel");
}
</script>

<style scoped>
.p-float-label > input,
.p-float-label > .p-dropdown {
  width: 100%;
}

.field-checkbox {
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
  }
}
</style>
