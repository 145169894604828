import { createStore } from "vuex";
import axios from "axios";
import createPresistedState from "vuex-persistedstate";
import authModule from "./auth";
import showingsModule from "./showings";
import userModule from "./user";
import integrationsModule from "./integrations";
import textTemplates from "./text-templates";
import conversations from "./conversations";
import contact from "./contact";
import properties from "./properties";

const store = createStore({
  modules: {
    auth: authModule,
    showings: showingsModule,
    user: userModule,
    integrations: integrationsModule,
    textTemplates: textTemplates,
    conversations: conversations,
    contact: contact,
    propeties: properties,
  },
  state() {
    return {
      navBarHeight: null,
      intranetSettings: [],
      tabsBottom: null,
      breadcrumbs: [],
      showingsConfig: {},
      ownersConfig: {},
    };
  },
  actions: {
    async loadIntranetSettings(context) {
      try {
        const settings = await axios.get(`${process.env.VUE_APP_API_URL}/settings`, {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        });

        context.commit("setIntranetSettings", settings.data);
      } catch (error) {
        console.log(error);
      }
    },
    async newIntranetSetting(context, { key, value, notes }) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/settings`,
          {
            key,
            value,
            notes,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async updateIntranetSetting(context, { id, value, notes }) {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/settings/${id}`,
          {
            value,
            notes,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    setNavBarHeight(state, payload) {
      state.navBarHeight = payload;
    },
    setIntranetSettings(state, payload) {
      state.intranetSettings = payload.settings;
      state.showingsConfig = payload.showingsConfig;
      state.ownersConfig = payload.ownersConfig;
    },
    setTabsBottom(state, payload) {
      state.tabsBottom = payload;
    },
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    },
  },
  getters: {
    navBarHeight(state) {
      return state.navBarHeight;
    },
    intranetSettings(state) {
      return state.intranetSettings;
    },
    tabsBottom(state) {
      return state.tabsBottom;
    },
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    showingsConfig(state) {
      return state.showingsConfig;
    },
    ownersConfig(state) {
      return state.ownersConfig;
    },
  },
  plugins: [
    createPresistedState({
      storage: window.sessionStorage,
    }),
  ],
});

export default store;

const openOAuth = (path, title, w, h) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  window.open(
    path,
    title,
    `location=1,
    status=1,
    scrollbars=1,
    width=${w / systemZoom},
    height=${h / systemZoom},
    left=${left},
    top=${top}`
  );
};

export { openOAuth };
