<template>
  <Menubar :model="items" class="main-nav-bar">
    <template #start>
      <img
        src="https://cdn.maxxpm.com/MPM_logo_w.png"
        alt=""
        height="80"
        loading="lazy"
        @click="$router.push({ path: '/' })"
        class="mpm-logo" />
    </template>
    <template #end>
      <router-link to="/settings/user/signature" class="menuitem-end" v-tooltip.top="'Settings'">
        <i class="pi pi-cog"></i>
      </router-link>
      <router-link to="/company-directory" class="menuitem-end" v-tooltip.top="'Company Directory'">
        <i class="pi pi-users"></i>
      </router-link>
      <a class="menuitem-end" @click="logout" v-tooltip.top="'Sign-Out'">
        <i class="pi pi-sign-out"></i>
      </a>
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  components: {
    Menubar,
  },
  data() {
    return {
      userPopover: false,
      collapseNav: false,
      conversationsDropdown: false,
      items: [
        {
          label: "Showings",
          to: "/showings",
        },
        {
          label: "Conversations",
          items: [
            {
              label: "SMS Inbox",
              to: "/conversations/inbox",
            },
            {
              label: "Templates",
              to: "/conversations/templates",
            },
          ],
        },
        {
          label: "Maps",
          to: "/maps",
        },
        {
          label: "Policies & Procedures",
          to: "/knowledge",
        },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isManagment() {
      if (this.$store.getters.isManagement || this.$store.getters.isAdmin) {
        return true;
      } else {
        return false;
      }
    },
    unreadCount() {
      return this.$store.getters.unreadCount;
    },
  },
  mounted() {
    const navBar = document.querySelector(".main-nav-bar");
    this.$store.commit("setNavBarHeight", navBar.offsetHeight);
    const role = this.$store.getters.userRole;
    if (role.scorecard_access) {
      this.items.push({
        label: "Scorecards",
        to: "/scorecards",
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/mdb/scss/standard/free/_variables.scss";

:deep(.navbar-nav) {
  flex: 1 1 auto;
  .btn-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: right;
  }
}

:deep(.sidebar-btn) {
  color: rgba(255, 255, 255, 0.55) !important;
}

.sidebar-btn:hover::v-deep {
  color: rgba(255, 255, 255, 0.75) !important;
}

.dropdown-nav:hover {
  cursor: pointer;
}

#app {
  .p-menubar {
    background: var(--mdb-secondary);
    border: none;
    border-radius: 0;
    :deep(.p-menuitem) {
      .p-menuitem-link {
        .p-menuitem-text {
          color: rgba(255, 255, 255, 0.55);
        }
      }
      :hover {
        background: var(--mdb-secondary);
        .p-menuitem-text {
          color: rgba(255, 255, 255, 0.75);
        }
      }
      .p-submenu-list {
        background: $secondary-dark;
        z-index: 1010;
        .p-menuitem-link {
          background: $secondary-dark;
          .p-menuitem-text {
            color: rgba(255, 255, 255, 0.55);
          }
        }
        .p-menuitem-link:hover {
          background: $secondary;
          .p-menuitem-text {
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
      .p-menuitem-link:focus {
        box-shadow: none;
      }
    }

    :deep(.p-menuitem-active) {
      background: $secondary-dark;
      .p-menuitem-link {
        background: $secondary-dark;
        .p-menuitem-text {
          background: transparent;
        }
      }
    }
  }

  .p-menubar-mobile-active {
    :deep(.p-menubar-root-list) {
      background: $secondary;
    }

    :deep(.p-menubar-button) {
      background: $secondary;
      color: rgba(255, 255, 255, 0.55);
    }

    :deep(.p-menubar-button:focus) {
      box-shadow: none;
    }
  }
}

.menuitem-end {
  color: rgba(255, 255, 255, 0.55);
  margin: 20px;
  i {
    font-size: 18px;
  }
}

.menuitem-end:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75);
}

.mpm-logo:hover {
  cursor: pointer;
}

.main-nav-bar {
  z-index: 1;
  flex: 0 1 auto;
}

@media screen and (max-width: 960px) {
  .mpm-logo {
    display: none;
  }

  :deep(.p-menubar-button:hover) {
    background: $secondary;
    box-shadow: none;
  }
}
</style>
