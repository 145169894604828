import axios from "axios";

export default {
  state() {
    return {
      propertyGroups: [],
    };
  },
  actions: {
    async storeNewPropertyGroup(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/property-groups`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error.response);
      }
    },

    async updatePropertyGroup(context, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/property-groups/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error.response);
      }
    },

    async loadPropertyGroups(context) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/property-groups`,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
        context.commit("setPropertyGroups", response.data);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mutations: {
    setPropertyGroups(state, payload) {
      state.propertyGroups = payload;
    },
  },
  getters: {
    propertyGroups(state) {
      return state.propertyGroups;
    },
  },
};
