import axios from "axios";

export default {
  state() {
    return {
      userId: null,
      firstName: null,
      lastName: null,
      phone: null,
      isAdmin: false,
      isManagement: false,
      roleId: null,
      userRole: null,
      numberSearchResults: null,
      calendarSubscription: null,
      users: null,
      userRoles: null,
      roleError: null,
      signatureSettings: null,
      userEmail: null,
      zoomNumber: null,
      zoomExt: null,
    };
  },
  actions: {
    async getUser(context) {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/users/${context.getters.userId}`, {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      });
      context.commit("setUser", {
        userId: res.data.id,
        isAdmin: res.data.is_admin,
        firstName: res.data.firstname,
        lastName: res.data.lastname,
        phone: res.data.twilio_number,
        isManagement: res.data.is_management,
        signatureSettings: res.data.signature_settings,
        userEmail: res.data.email,
        zoomNumber: res.data.zoom_number,
        zoomExt: res.data.zoom_extension,
        userRole: res.data.job_title,
      });

      context.dispatch("loadUserConversations");
    },

    async searchForNumbers(context, payload) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/twilio/find-numbers`,
        {
          areaCode: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      context.commit("setSearchResults", response.data);
      setTimeout(() => {
        if (context.getters.numberSearchResults !== null) {
          context.commit("setSearchResults", null);
        }
      }, 300000);
    },

    async purchaseNumber(context, payload) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/twilio/purchase-number`,
        {
          phoneNumber: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      context.commit("setPhone", response.data.twilio_number);
    },

    async getCalendarSubscription(context) {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/microsoft/calendar-subscription`, {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      });

      context.commit("setCalendarSubscription", response.data[0]);
    },

    async reconnectCalendarSubscription(context) {
      await axios.get(`${process.env.VUE_APP_API_URL}/microsoft/reconnect-calendar`, {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      });
    },

    async getUsers(context) {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      });

      context.commit("setUsers", response.data);
    },

    async getUserRoles(context) {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/user-roles`, {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      });

      context.commit("setUserRoles", response.data);
    },

    async saveUserRole(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/users/set-role`,
          {
            userId: payload.userId,
            roleId: payload.roleId,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        context.commit("setRoleError", null);
        context.commit("setRoleId", payload.roleId);
        context.dispatch("getUsers");
        context.dispatch("getUserRoles");
      } catch (error) {
        console.log(error.response);
      }
    },

    async createNewUserRole(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/user-roles`,
          {
            role: payload.role,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        // await context.dispatch("saveUserRole", {
        //   userId: payload.userId,
        //   roleId: response.data.id,
        // });
      } catch (error) {
        if (error.response.data.message.includes("ER_DUP_ENTRY")) {
          context.commit("setRoleError", "ER_DUP_ENTRY");
        }
      }
    },

    async getUserRole(context) {
      try {
        if (context.getters.roleId !== null) {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/user-roles/${context.getters.roleId}`, {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          });

          context.commit("setUserRole", response.data.role);
        }
      } catch (error) {
        console.log(error.response);
      }
    },

    async updateUser(context, payload) {
      try {
        const res = await axios.put(`${process.env.VUE_APP_API_URL}/users/${context.getters.userId}`, payload, {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        });

        context.commit("setUser", {
          userId: res.data.id,
          isAdmin: res.data.is_admin,
          firstName: res.data.firstname,
          lastName: res.data.lastname,
          phone: res.data.twilio_number,
          isManagement: res.data.is_management,
          signatureSettings: res.data.signature_settings,
          userEmail: res.data.email,
          zoomNumber: res.data.zoom_number,
          zoomExt: res.data.zoom_extension,
          userRole: res.data.job_title,
        });
      } catch (error) {
        if (error.isAxiosError) {
          console.log(error.response);
        }
        throw error;
      }
    },
  },
  mutations: {
    setUser(state, payload) {
      state.userId = payload.userId;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.phone = payload.phone;
      state.isAdmin = payload.isAdmin === 1 ? true : false;
      state.isManagement = payload.isManagement === 1 ? true : false;
      state.signatureSettings = payload.signatureSettings;
      state.userEmail = payload.userEmail;
      state.zoomNumber = payload.zoomNumber;
      state.zoomExt = payload.zoomExt;
      state.userRole = payload.userRole;
    },
    setSearchResults(state, payload) {
      state.numberSearchResults = payload;
    },
    setPhone(state, payload) {
      state.phone = payload;
    },
    setCalendarSubscription(state, payload) {
      state.calendarSubscription = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUserRoles(state, payload) {
      state.userRoles = payload;
    },
    setRoleId(state, payload) {
      state.roleId = payload;
    },
    setRoleError(state, payload) {
      state.roleError = payload;
    },
    setUserRole(state, payload) {
      state.userRole = payload;
    },
    updateUser(state, payload) {
      const i = state.users.findIndex((user) => user.id === payload.id);
      state.users[i] = payload;
    },
    setUserSignatureSettings(state, payload) {
      state.signatureSettings = payload;
    },
    setUserEmail(state, payload) {
      state.userEmail = payload;
    },
    setZoomNumber(state, payload) {
      state.zoomNumber = payload;
    },
    setZoomExt(state, payload) {
      state.zoomExt = payload;
    },
  },
  getters: {
    userId(state) {
      return state.userId;
    },
    userFirstName(state) {
      return state.firstName;
    },
    userFullName(state) {
      return `${state.firstName} ${state.lastName}`;
    },
    userPhone(state) {
      return state.phone;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isManagement(state) {
      return state.isManagement;
    },
    numberSearchResults(state) {
      return state.numberSearchResults;
    },
    userCalendarSubscription(state) {
      return state.calendarSubscription;
    },
    users(state) {
      return state.users;
    },
    userRoles(state) {
      return state.userRoles;
    },
    roleId(state) {
      return state.roleId;
    },
    roleError(state) {
      return state.roleError;
    },
    userRole(state) {
      return state.userRole;
    },
    userSignatureSettings(state) {
      return state.signatureSettings;
    },
    userEmail(state) {
      return state.userEmail;
    },
    zoomNumber(state) {
      return state.zoomNumber;
    },
    zoomExt(state) {
      return state.zoomExt;
    },
  },
};
