import axios from "axios";

export default {
  state() {
    return {
      userConversations: [],
      activeConversation: null,
      messages: [],
      unreadCount: 0,
    };
  },
  actions: {
    async loadUserConversations(context) {
      const conversations = await axios.get(
        `${process.env.VUE_APP_API_URL}/sms/contacts`,
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );

      const unreadCount = conversations.data.filter(
        (msg) => msg.last_message_read === 0
      );

      context.commit("setUserConversations", conversations.data);
      context.commit("setUnreadCount", unreadCount.length);
    },

    async newConversation(context, payload) {
      const contact = await axios.post(
        `${process.env.VUE_APP_API_URL}/contacts/findbyphone`,
        {
          phone: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      context.commit("setActiveConversation", contact.data);
    },

    async loadContact(context, payload) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/sms/contact/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      context.commit("setActiveConversation", response.data.contact);
      context.commit("setMessages", response.data.messages);
    },
  },
  mutations: {
    setUserConversations(state, payload) {
      state.userConversations = payload;
    },
    setActiveConversation(state, payload) {
      state.activeConversation = payload;
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    setUnreadCount(state, payload) {
      state.unreadCount = payload;
    },
  },
  getters: {
    userConversations(state) {
      return state.userConversations;
    },
    activeConversation(state) {
      return state.activeConversation;
    },
    messages(state) {
      return state.messages;
    },
    unreadCount(state) {
      return state.unreadCount;
    },
  },
};
