import axios from "axios";
import store from "../store/store";

export class Contact {
  constructor() {}

  static async getSchema() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/schema/contact`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const schema = [];

      res.data.forEach((column) => {
        const value = column[1];
        if (value.meta && value.meta.columnLabel) {
          schema.push(value);
        }
      });

      return schema;
    } catch (error) {
      console.error(error);
    }
  }
}
