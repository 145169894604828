import store from "../store/store";
import axios from "axios";
import { reactive } from "vue";
import { DateTime, SystemZone } from "luxon";

export class Showing {
  id;
  user_id;
  contact_id;
  start_time;
  end_time;
  checked_in;
  ms_event_id;
  hs_deal_id;
  hs_engagement_id;
  hs_contact_id;
  meeting_confirmed;
  created_at;
  updated_at;
  user;
  contact;
  walk_in;

  static async getSchema() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/schema/showing`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const schema = [];
      res.data.forEach((column) => {
        if (column[1].meta && column[1].meta.columnLabel) {
          schema.push(column[1]);
        }
      });

      return schema;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async checkIn() {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/showings/check-in`,
        {
          showingId: this.id,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async noShow() {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/showings/no-show`,
        {
          showingId: this.id,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async confirm() {
    try {
      await axios.put(
        `${process.env.VUE_APP_API_URL}/showings/confirm/${this.id}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async sendText(messageBody, placeholder = null) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/showings/send-texts`,
        {
          contactId: this.contact_id,
          showingId: this.id,
          messageBody,
          placeholder,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  getStartTime() {
    if (this.walk_in) {
      return "Walk-In";
    }
    const utcDate = new Date(this.start_time).toUTCString();
    const year = new Date(utcDate).getUTCFullYear();
    const month = new Date(utcDate).getUTCMonth();
    const date = new Date(utcDate).getUTCDate();
    const hour = new Date(utcDate).getUTCHours();
    const min = new Date(utcDate).getUTCMinutes();
    const sec = new Date(utcDate).getUTCSeconds();
    const mil = new Date(utcDate).getUTCMilliseconds();
    const zone = new SystemZone();
    const startTime = DateTime.utc(
      year,
      month + 1,
      date,
      hour,
      min,
      sec,
      mil
    ).setZone(zone);
    return startTime.toFormat("t");
  }

  static async create({
    firstName,
    lastName,
    email,
    phone,
    unitId,
    walkIn,
    start,
    end,
  }) {
    try {
      if (!email) {
        const invalidFields = [];
        if (!email) {
          invalidFields.push({ id: "email", value: email });
        }
        throw new InvalidShowing(invalidFields, "Missing required form fields");
      }
      await axios.post(
        `${process.env.VUE_APP_API_URL}/showings/walk-in`,
        {
          userId: store.getters.userId,
          firstName,
          lastName,
          email,
          phone,
          unitId,
          walkIn,
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }
}

export class InvalidShowing extends Error {
  constructor(invalidFields, message = null) {
    super(message);

    this.invalidFields = invalidFields;
  }
}

export const showings = reactive({
  all: [],
  async loadAll(showingDate) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/showings/${showingDate}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const _showings = [];

      res.data.forEach((showing) => {
        const _showing = new Showing();
        _showing.id = showing.id;
        _showing.user_id = showing.user_id;
        _showing.contact_id = showing.contact_id;
        _showing.start_time = showing.start_time;
        _showing.end_time = showing.end_time;
        _showing.checked_in = showing.checked_in;
        _showing.ms_event_id = showing.ms_event_id;
        _showing.hs_deal_id = showing.hs_deal_id;
        _showing.hs_engagement_id = showing.hs_engagement_id;
        _showing.hs_contact_id = showing.hs_contact_id;
        _showing.meeting_confirmed = showing.meeting_confirmed;
        _showing.created_at = showing.created_at;
        _showing.updated_at = showing.updated_at;
        _showing.user = showing.user;
        _showing.contact = showing.contact;
        _showing.walk_in = showing.walk_in;

        _showings.push(_showing);
      });

      this.all = _showings;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  },
});
