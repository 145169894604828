import { openOAuth } from "./store";
import axios from "axios";

let timer;
let proxyTimer;

export default {
  state() {
    return {
      token: null,
      tokenExpiration: null,
      isLoggedIn: false,
      adminId: null,
      adminToken: null,
      adminExpiration: null,
      proxyFullname: null,
    };
  },
  actions: {
    login(context) {
      openOAuth(`${process.env.VUE_APP_API_URL}/login`, "login", 800, 800);
      window.addEventListener("message", async (message) => {
        if (message.data.token) {
          // localStorage.setItem("token", message.data.token.token);
          // localStorage.setItem(
          //   "tokenExpiration",
          //   message.data.token.expires_at
          // );

          const now = new Date().getTime();
          const expiresAt = new Date(message.data.token.expires_at).getTime();

          const expiresIn = expiresAt - now;

          timer = setTimeout(() => {
            context.dispatch("logout");
          }, expiresIn);

          context.commit("setToken", {
            token: message.data.token.token,
            tokenExpiration: message.data.token.expires_at,
          });
          context.commit("setUserId", message.data.userId);
          await context.dispatch("getUser");
          context.commit("setLogin");
        } else {
          context.commit("addIntegration", {
            appName: message.data.appName,
            status: message.data.status,
          });
        }
      });
    },
    async autoLogin(context) {
      // const token = localStorage.getItem("token");
      // const tokenExpiration = localStorage.getItem("tokenExpiration");
      const token = context.getters.token;
      const tokenExpiration = context.getters.tokenExpiration;

      const now = new Date().getTime();
      const expiresAt = new Date(tokenExpiration).getTime();

      const expiresIn = expiresAt - now;

      if (expiresIn < 0) {
        return context.dispatch("logout");
      }

      timer = setTimeout(() => {
        context.dispatch("logout");
      }, expiresIn);

      if (token) {
        context.commit("setToken", {
          token: token,
          tokenExpiration: tokenExpiration,
        });
        await context.dispatch("getUser");
        context.commit("setLogin");
      }
    },
    async logout(context) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("tokenExpiration");
      context.commit("setLogout");

      clearTimeout(timer);

      try {
        await axios.get(`${process.env.VUE_APP_API_URL}/logout`, {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        });
        context.commit("setToken", { token: null, tokenExpiration: null });
      } catch (error) {
        console.log(error);
      }
    },
    async proxyLogin(context, payload) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/proxy_login/${payload.id}`, {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        });

        context.commit("setPlaceholders", {
          id: context.getters.userId,
          token: context.getters.token,
          expiration: context.getters.tokenExpiration,
          fullname: payload.fullname,
        });

        const now = new Date().getTime();
        const expiresAt = new Date(res.data.expires_at).getTime();

        const expiresIn = expiresAt - now;

        proxyTimer = setTimeout(() => {
          context.dispatch("proxyLogout");
        }, expiresIn);

        context.commit("setToken", {
          token: res.data.token,
          tokenExpiration: res.data.expires_at,
        });
        context.commit("setUserId", payload.id);
        context.dispatch("getUser");
      } catch (error) {
        if (error.isAxiosError) {
          console.log(error.response);
        }
        throw error;
      }
    },
    async proxyLogout(context) {
      clearTimeout(proxyTimer);
      context.commit("setToken", {
        token: context.getters.adminToken,
        tokenExpiration: context.getters.adminExpiration,
      });
      context.commit("setUserId", context.getters.adminId);
      await context.dispatch("getUser");
      context.commit("setPlaceholders", {
        id: null,
        token: null,
        expiration: null,
        fullname: null,
      });
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload.token;
      state.tokenExpiration = payload.tokenExpiration;
    },
    setLogin(state) {
      state.isLoggedIn = true;
    },
    setLogout(state) {
      state.isLoggedIn = false;
    },
    setPlaceholders(state, payload) {
      state.adminId = payload.id;
      state.adminToken = payload.token;
      state.adminExpiration = payload.expiration;
      state.proxyFullname = payload.fullname;
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    tokenExpiration(state) {
      return state.tokenExpiration;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    adminId(state) {
      return state.adminId;
    },
    adminToken(state) {
      return state.adminToken;
    },
    adminExpiration(state) {
      return state.adminExpiration;
    },
    proxyFullname(state) {
      return state.proxyFullname;
    },
  },
};
