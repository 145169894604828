<template>
  <div class="p-editor-container">
    <div ref="toolbarElement" class="p-editor-toolbar">
      <slot name="toolbar">
        <span class="ql-formats">
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
        </span>
        <span class="ql-formats">
          <button class="ql-link" />
        </span>
        <span class="ql-formats">
          <button class="ql-clean" />
        </span>
        <span class="ql-formats">
          <Button
            class="personalize-btn p-button-link p-button-sm"
            icon="pi pi-angle-down"
            label="Personalize"
            @click="togglePersonalize"
          ></Button>
        </span>

        <OverlayPanel
          ref="personalize"
          style="width: 350px"
          @hide="clearPersonalzeOverlay"
        >
          <p class="fs-5 text-black">Insert personalization token</p>
          <div class="d-flex flex-column mb-2">
            <label for="tokenType">Type</label>
            <Dropdown
              id="tokenType"
              v-model="selectedTokenType"
              :options="tokenTypes"
              @change="onTokenTypeChange"
            />
            <div
              v-if="
                selectedTokenType === 'Showing' || selectedTokenType === 'Unit'
              "
            >
              <div class="help-container" v-tooltip="helpText">
                <p class="help-label">Limited use</p>
                <div class="help-icon">
                  <i class="fas fa-question"></i>
                </div>
              </div>
            </div>
          </div>
          <Listbox
            v-if="selectedTokenSchema && !dateTimeToken"
            v-model="selectedProperty"
            :options="selectedTokenSchema"
            optionLabel="meta.columnLabel"
            :filter="true"
            listStyle="max-height: 200px"
            @change="propertySelected"
          />
          <div v-else-if="selectedTokenSchema && dateTimeToken">
            <Chip
              :label="tokenValue"
              class="custom-chip"
              removable
              @remove="onTokenTypeChange"
            />
            <div class="m-3">
              <div class="field-radiobutton">
                <RadioButton
                  id="date"
                  name="datetime"
                  value="date"
                  v-model="dateTimeValue"
                />
                <label for="date">Date</label>
                <i class="pi pi-calendar"></i>
              </div>
              <div class="field-radiobutton">
                <RadioButton
                  id="time"
                  name="datetime"
                  value="time"
                  v-model="dateTimeValue"
                />
                <label for="time">Time</label>
                <i class="pi pi-clock"></i>
              </div>
            </div>
          </div>
          <div
            v-else-if="selectedTokenType === 'Placeholder'"
            class="d-flex flex-column"
          >
            <label for="placeholderToken">Placeholder token</label>
            <InputText v-model="placeholderValue" class="mb-2" />
            <Message severity="warn" :closable="false">
              Placeholder tokens will require a value before sending a template.
            </Message>
          </div>
          <div class="mt-2">
            <Button
              label="Insert"
              class="me-3"
              :disabled="disableInsert"
              @click="insertToken"
            />
            <Button
              label="Cancel"
              class="p-button-outlined"
              @click="hidePersonalize"
            />
          </div>
        </OverlayPanel>
      </slot>
    </div>
    <div ref="editorElement" :style="editorStyle"></div>
  </div>
</template>

<script>
import Quill from "quill";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import Listbox from "primevue/listbox";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import Chip from "primevue/chip";
import RadioButton from "primevue/radiobutton";
import { Contact } from "../services/contacts";
import { User } from "../services/users";
import { Showing } from "../services/showings";
import { Unit } from "../services/units";

export default {
  name: "Quill",
  components: {
    Button,
    OverlayPanel,
    Listbox,
    Dropdown,
    InputText,
    Message,
    Chip,
    RadioButton,
  },
  emits: ["update:modelValue", "text-change", "added-placeholder"],
  props: {
    modelValue: String,
    placeholder: String,
    readonly: Boolean,
    formats: Array,
    editorStyle: null,
  },
  data() {
    return {
      selectedTokenType: "Contact",
      tokenTypes: ["Contact", "Sender", "Showing", "Unit", "Placeholder"],
      selectedProperty: null,
      placeholderValue: null,
      contactSchema: null,
      senderSchema: null,
      showingSchema: null,
      unitSchema: null,
      lastRangeIndex: 0,
      dateTimeToken: false,
      dateTimeValue: null,
      tokenValue: null,
    };
  },
  computed: {
    selectedTokenSchema() {
      switch (this.selectedTokenType) {
        case "Contact":
          return this.contactSchema;
        case "Sender":
          return this.senderSchema;
        case "Showing":
          return this.showingSchema;
        case "Unit":
          return this.unitSchema;
        default:
          return null;
      }
    },
    disableInsert() {
      if (this.selectedTokenType === "Placeholder" && this.placeholderValue) {
        return false;
      }

      if (
        this.selectedProperty &&
        this.selectedTokenType !== "Placeholder" &&
        !this.dateTimeToken
      ) {
        return false;
      }

      if (this.dateTimeToken && this.dateTimeValue) {
        return false;
      }

      return true;
    },

    helpText() {
      let tooltip = { class: "help-tooltip" };
      if (this.selectedTokenType === "Showing") {
        tooltip.value =
          "Showing tokens are only available on messages that are sent from the showings page. If the tokens are used elsewhere, the token will show a blank value.";
      } else if (this.selectedTokenType === "Unit") {
        tooltip.value =
          "Unit tokens are only avaiable on messages that are sent from the showings page, or within the sms messages sent as a follow up to showings. If the tokens are used elsewhere, the token will show a blank value.";
      }
      return tooltip;
    },
  },
  quill: null,
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue !== oldValue && this.quill && !this.quill.hasFocus()) {
        this.renderValue(newValue);
      }
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.editorElement, {
      modules: {
        toolbar: this.$refs.toolbarElement,
      },
      readOnly: this.readonly,
      theme: "snow",
      formats: this.formats,
      placeholder: this.placeholder,
    });

    this.renderValue(this.modelValue);

    this.quill.on("text-change", (delta, oldContents, source) => {
      if (source === "user") {
        let html = this.$refs.editorElement.children[0].innerHTML;
        let text = this.quill.getText().trim();
        if (html === "<p><br></p>") {
          html = "";
        }

        this.$emit("update:modelValue", html);
        this.$emit("text-change", {
          htmlValue: html,
          textValue: text,
          delta: delta,
          source: source,
          instance: this.quill,
        });
      }
    });

    this.quill.on("selection-change", (range, or) => {
      if (range) {
        this.lastRangeIndex = range.index;
      } else {
        this.lastRangeIndex = or.index;
      }
    });
  },
  methods: {
    renderValue(value) {
      if (this.quill) {
        if (value) this.quill.pasteHTML(value);
        else this.quill.setText("");
      }
    },
    async togglePersonalize(e) {
      this.$refs.personalize.toggle(e);
      this.contactSchema = await Contact.getSchema();
      this.senderSchema = await User.getSchema();
      this.showingSchema = await Showing.getSchema();
      this.unitSchema = await Unit.getSchema();
    },
    onTokenTypeChange() {
      this.dateTimeToken = false;
      this.tokenValue = null;
      this.dateTimeValue = null;
      this.selectedProperty = null;
      this.placeholderValue = null;
    },
    propertySelected(e) {
      if (e.value.meta.type === "datetime") {
        this.dateTimeToken = true;

        let token;
        switch (this.selectedTokenType) {
          case "Contact":
            token = `Contact - ${e.value.meta.columnLabel}`;
            break;
          case "Sender":
            token = `Sender - ${e.value.meta.columnLabel}`;
            break;
          case "Showing":
            token = `Showing - ${e.value.meta.columnLabel}`;
            break;
          case "Unit":
            token = `Unit - ${e.value.meta.columnLabel}`;
        }
        this.tokenValue = token;
      }
    },
    insertToken() {
      let token;
      switch (this.selectedTokenType) {
        case "Contact":
          token = this.setModelToken("contact");
          break;
        case "Sender":
          token = this.setModelToken("sender");
          break;
        case "Showing":
          token = this.setModelToken("showing");
          break;
        case "Unit":
          token = this.setModelToken("unit");
          break;
        case "Placeholder":
          token = this.placeholderValue;
          token = `{{placeholder.${this.toSnakeCase(token)}}}`;
          this.$emit(
            "added-placeholder",
            this.toSnakeCase(this.placeholderValue)
          );
      }

      this.quill.insertText(this.lastRangeIndex, token, "user");
      this.quill.setSelection(this.lastRangeIndex + token.length, 0, "user");
      this.hidePersonalize();
    },
    toSnakeCase(val) {
      return val
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join("_");
    },
    setModelToken(model) {
      let token = `${model}.${this.selectedProperty.serializeAs}`;
      if (this.selectedProperty.meta.type === "datetime") {
        token = `${token}.${this.dateTimeValue}`;
      }
      return `{{${token}}}`;
    },
    hidePersonalize() {
      this.$refs.personalize.hide();
      this.clearPersonalzeOverlay();
    },
    clearPersonalzeOverlay() {
      this.dateTimeToken = false;
      this.dateTimeValue = null;
      this.tokenValue = null;
      this.selectedProperty = null;
      this.placeholderValue = null;
      this.selectedTokenType = "Contact";
    },
  },
  beforeUnmount() {
    this.quill = null;
  },
};
</script>

<style lang="scss" scoped>
.p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.field-radiobutton {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  label,
  i {
    margin-left: 0.5rem;
  }
}

.personalize-btn {
  width: auto !important;
  height: auto !important;
}

.help-container {
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
  min-width: 0;
  flex-basis: 0;
}

.help-container:hover {
  cursor: help;
}

.help-label {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--mdb-info);
  margin-right: 0.5rem;
  line-height: 1;
}

.help-icon {
  border-radius: 50%;
  background: var(--mdb-info);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: white;
    font-size: 10px;
  }
}

.help-tooltip {
  font-size: 12px;
}
</style>
