<template>
  <MDBCard class="align-items-center error-card">
    <MDBCardTitle class="error-code">{{ errorCode }}</MDBCardTitle>
    <MDBCardBody class="d-flex flex-column align-items-center">
      <h3>{{ errorTitle }}</h3>
      <p>{{ errorMsg }}</p>
      <MDBBtn tag="a" color="secondary" rounded :href="href">{{
        btnText
      }}</MDBBtn>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBCard, MDBCardTitle, MDBCardBody, MDBBtn } from "mdb-vue-ui-kit";
export default {
  components: {
    MDBCard,
    MDBCardTitle,
    MDBCardBody,
    MDBBtn,
  },
  props: {
    errorCode: Number,
    errorTitle: String,
    errorMsg: String,
    href: String,
    btnText: String,
  },
};
</script>

<style scoped>
.error-code {
  font-size: 100px;
  color: lightgrey;
}

.error-card {
  margin: 10% 0;
}
</style>
