import axios from "axios";

export default {
  state() {
    return {
      textTemplates: [],
    };
  },
  actions: {
    async loadTemplates(context) {
      try {
        const templates = await axios.get(
          `${process.env.VUE_APP_API_URL}/templates/text`,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        context.commit("setTemplates", templates.data);
      } catch (error) {
        console.log(error);
      }
    },
    async createTemplate(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/templates/text`,
          {
            name: payload.name,
            message: payload.message,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async updateTemplate(context, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/templates/text/${payload.id}`,
          {
            template_name: payload.name,
            message: payload.message,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    setTemplates(state, payload) {
      state.textTemplates = payload;
    },
  },
  getters: {
    templates(state) {
      return state.textTemplates;
    },
  },
};
