<template>
  <div class="d-flex flex-column align-items-center">
    <MDBContainer
      fluid
      class="p-0"
      v-if="
        showingsConfig.providers &&
        showingsConfig.providers.includes('hubspot') &&
        subscriptionExpired
      "
    >
      <div
        class="d-flex justify-content-center align-items-center bg-danger p-2"
      >
        <p class="text-white m-0 pe-4">Your calendar is no longer connected</p>
        <MDBSpinner v-if="isConnecting" color="secondary" />
        <MDBBtn v-else color="secondary" @click="reconnectCalendar"
          >Re-Connect</MDBBtn
        >
      </div>
    </MDBContainer>
    <MDBContainer fluid class="mt-3">
      <MDBContainer class="p-0">
        <MDBRow class="gx-1 mb-3 flex-grow-1">
          <MDBCol col="8" sm="6" class="d-flex justify-content-start">
            <Calendar
              v-model="showingDate"
              :showIcon="true"
              :touchUI="smallScreen"
              @date-select="changeDate"
            />
          </MDBCol>
          <MDBCol col="4" sm="6" class="d-flex justify-content-end">
            <MDBBtn
              color="secondary"
              @click="openTextDialog"
              :disabled="showings.length < 0 || !isCurrentDate"
            >
              SEND TEXTS
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow>
        <hr />
      </MDBRow>
    </MDBContainer>
    <MDBContainer class="mt-2 d-flex justify-content-center flex-column">
      <div v-if="isLoading" class="d-flex justify-content-center mb-3">
        <MDBSpinner color="primary" />
      </div>
      <MDBRow
        v-else-if="!isLoading && showings.length > 0"
        v-for="showing in showings"
        :key="showing.id"
        class="flex-grow-1"
      >
        <MDBCol class="d-flex justify-content-center">
          <showing-card :showing="showing"></showing-card>
        </MDBCol>
      </MDBRow>
      <div v-else class="text-center">
        <p>
          You do not have any showings scheduled for
          {{
            showingDate.toLocaleString("en-US", {
              weekday: "long",
              month: "long",
              day: "2-digit",
            })
          }}
        </p>
      </div>
      <MDBRow class="mb-3">
        <MDBCol col="12" class="d-flex justify-content-center">
          <Button
            icon="pi pi-plus"
            class="p-button-outlined p-button-rounded"
            v-tooltip.bottom="'Add Showing'"
            @click="openNewShowing"
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>

  <Dialog
    header="Create Showing"
    v-model:visible="newShowingModal"
    modal
    :style="{ width: '50vw' }"
    @hide="cancelNewWalkIn"
    :breakpoints="{
      '1200px': '70vw',
      '992px': '80vw',
      '768px': '90vw',
      '576px': '100vw',
    }"
  >
    <div v-if="showingsConfig.providers.includes('calendly')">
      <Transition name="slide-fade">
        <div v-if="!isWalkIn">
          <div class="field-checkbox mb-2">
            <Checkbox id="walkInCheckbox" v-model="isWalkIn" binary />
            <label for="walkInCheckbox"><strong>Walk-In</strong></label>
          </div>
          <label for="eventTypes">Calendly Event</label>
          <Dropdown
            id="eventTypes"
            :options="userEventTypes"
            optionLabel="name"
            v-model="selectedEventType"
            :loading="loadingEventTypes"
            class="w-100"
            @change="onEventTypeChange"
          />
          <div id="calendlyWidget"></div>
        </div>
        <div v-else>
          <NewShowingForm
            :newShowingDateTime="newShowingDateTime"
            :prevDay="prevDay"
            :start="start"
            :end="end"
            :isWalkIn="isWalkIn"
            :time="time"
            @back="backToTimeSelect"
            @cancel="cancelNewWalkIn"
            @created="newShowingCreated"
          />
        </div>
      </Transition>
    </div>
    <div v-else>
      <Transition name="slide-fade">
        <div v-if="!timeSelected && !isWalkIn">
          <div class="field-checkbox mb-2">
            <Checkbox id="walkInCheckbox" v-model="isWalkIn" binary />
            <label for="walkInCheckbox"><strong>Walk-In</strong></label>
          </div>
          <Accordion>
            <AccordionTab header="Scheduling Options">
              <table class="variables-table">
                <tr>
                  <td>
                    <p>Duration:</p>
                  </td>
                  <td>
                    <InputNumber
                      v-model="duration"
                      showButtons
                      buttonLayout="horizontal"
                      :step="1"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      class="p-inputtext-sm auto-input"
                    />
                  </td>
                  <td>
                    <p>Minutes</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="d-flex align-items-center justify-content-end">
                      <i
                        class="pi pi-info-circle text-info me-2"
                        v-tooltip.top="'Time required in between showings'"
                      ></i>
                      <p>Buffer:</p>
                    </span>
                  </td>
                  <td>
                    <InputNumber
                      v-model="buffer"
                      showButtons
                      buttonLayout="horizontal"
                      :step="1"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      class="p-inputtext-sm auto-input"
                    />
                  </td>

                  <td>
                    <p>Minutes</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="d-flex justify-content-end align-items-center">
                      <i
                        class="pi pi-info-circle text-info me-2"
                        v-tooltip.top="'Frequency of showing start times'"
                      ></i>
                      <p>Start:</p>
                    </span>
                  </td>
                  <td>
                    <InputNumber
                      v-model="startIncrement"
                      showButtons
                      buttonLayout="horizontal"
                      :step="1"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      class="p-inputtext-sm auto-input"
                    />
                  </td>

                  <td>
                    <p>Minutes</p>
                  </td>
                </tr>
              </table>
            </AccordionTab>
          </Accordion>

          <Transition name="fade">
            <div v-if="!loadingTimes">
              <div class="m-2">
                <div class="d-flex">
                  <span class="me-2">Timezone:</span>
                  <a href="#" class="p-link" @click="openTimezoneOverlay">{{
                    timezone
                  }}</a>

                  <OverlayPanel ref="timezoneOverlay">
                    <Listbox
                      v-model="timezone"
                      :options="availTimeZones"
                      optionLabel="timezone"
                      optionValue="timezone"
                      listStyle="max-height: 350px"
                      filter
                      @change="$refs.timezoneOverlay.hide()"
                    />
                  </OverlayPanel>
                </div>
              </div>
              <Listbox
                v-model="newShowing.time"
                :options="availableTimes"
                optionLabel="label"
                style="width: 100%"
                listStyle="max-height: 400px;"
                @change="onStartSelected"
              >
              </Listbox>
            </div>
          </Transition>
          <div v-if="loadingTimes" class="loading-container">
            <div class="gif-container">
              <img src="https://cdn.maxxpm.com/mpm_logo.gif" alt="" />
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="slide-fade">
        <NewShowingForm
          v-if="timeSelected || isWalkIn"
          :newShowingDateTime="newShowingDateTime"
          :prevDay="prevDay"
          :start="start"
          :end="end"
          :isWalkIn="isWalkIn"
          :time="time"
          @back="backToTimeSelect"
          @cancel="cancelNewWalkIn"
        />
      </Transition>
    </div>
  </Dialog>

  <Dialog
    header="Send Text Messages"
    v-model:visible="showingsTexts"
    modal
    :style="{ width: '50vw' }"
    @hide="cancelTexts"
    :breakpoints="{
      '1200px': '70vw',
      '992px': '80vw',
      '768px': '90vw',
      '576px': '100vw',
    }"
  >
    <div v-if="!userPhone">
      <p class="m-2">A Twilio number is required to use this feature</p>
      <twilio-modal-body />
    </div>
    <div
      v-else-if="isSending && userPhone"
      class="d-flex justify-content-center align-items-center"
    >
      <img
        src="https://f.hubspotusercontent20.net/hubfs/6028923/mpm_logo.gif"
      />
    </div>
    <form v-else @submit.prevent="checkForm">
      <div class="d-flex align-items-center justify-content-between mt-3">
        <span>
          <Button
            label="My templates"
            @click="loadUserTemplates"
            class="p-button-link"
          />
          <Button
            label="Public templates"
            @click="loadPublicTemplates"
            class="p-button-link"
          />
        </span>
        <Button
          v-if="!newTemplate && !editTemplate && templateName === null"
          icon="pi pi-plus"
          class="p-button-text p-button-rounded"
          v-tooltip.top="'Create a new template'"
          @click="newTemplate = true"
        />
        <Button
          v-else-if="templateName !== null && !editTemplate && !newTemplate"
          icon="pi pi-pencil"
          class="p-button-text p-button-rounded"
          v-tooltip.top="'Edit this template'"
          @click="editTemplateClicked"
        />
      </div>
      <hr style="color: var(--mdb-primary)" />
      <MDBInput
        v-if="newTemplate || editTemplate"
        label="Template Name"
        v-model="templateName"
        class="mb-2 mt-2"
        validFeedback="Looks good!"
        invalidFeeback="Please provide a template name"
        required
        title="Template Name must be at lease 4 characters long"
        minLength="4"
        maxLength="60"
      ></MDBInput>
      <MDBContainer v-else-if="templateName !== null">
        <p class="template-label">Template Name</p>
        <p>{{ templateName }}</p>
      </MDBContainer>
      <Quill
        v-if="
          newTemplate ||
          editTemplate ||
          (!newTemplate && !editTemplate & (templateName === null))
        "
        editorStyle="height: 250px;"
        v-model="templateMessage"
        @text-change="templateUpdated"
        @added-placeholder="placeholderAdded"
      />
      <MDBContainer v-else>
        <p class="template-label">Message</p>
        <p>{{ templateMessage }}</p>
      </MDBContainer>
      <MDBContainer class="p-0" v-if="Object.keys(placeholders).length > 0">
        <hr style="color: var(--mdb-primary)" />
        <MDBRow>
          <MDBCol>
            <p class="mb-0">Placeholders:</p>
          </MDBCol>
        </MDBRow>
        <MDBRow class="gx-2 gy-2">
          <MDBCol
            col="12"
            md="4"
            v-for="(placeholder, key) in placeholders"
            :key="key"
          >
            <MDBInput
              v-model="placeholders[key]"
              :label="key"
              validFeedback="Looks good!"
              :invalidFeeback="`Please provide a value for ${key}`"
              required
            />
          </MDBCol>
        </MDBRow>
        <hr style="color: var(--mdb-primary)" />
      </MDBContainer>
      <div class="send-texts-submit-container mt-2">
        <div class="me-2 flex-grow-1 recipients-selector-container">
          <span class="d-flex align-items-end">
            <label class="me-2" for="select">Send to:</label>
            <MultiSelect
              id="select"
              v-model="textShowings"
              :options="textShowingOptions"
              optionLabel="contact.fullname"
            >
              <template #option="slotProps">
                <span class="flex-grow-1 d-flex justify-content-between">
                  <span>{{ slotProps.option.contact.fullname }}</span>
                  <span>{{ slotProps.option.getStartTime() }}</span>
                </span>
              </template>
            </MultiSelect>
          </span>
        </div>
        <div>
          <Button
            id="createAndSend"
            v-if="newTemplate && !editTemplate"
            label="Create & Send"
            :disabled="textShowings.length === 0"
            type="submit"
          />
          <Button
            id="updateAndSend"
            v-else-if="editTemplate && !newTemplate"
            label="Update & Send"
            :disabled="textShowings.length === 0"
            type="submit"
          />
          <Button
            id="sendTexts"
            v-else
            :disabled="textShowings.length === 0"
            type="submit"
            label="Send"
          />
          <Button
            label="Cancel"
            class="p-button-outlined p-button-secondary ms-2"
            @click="cancelTexts"
          />
        </div>
      </div>
    </form>
  </Dialog>

  <MDBModal
    id="templateList"
    tabindex="-1"
    lablelledby="templateListTitle"
    v-model="templateList"
    size="lg"
  >
    <MDBModalHeader>
      <MDBModalTitle id="templateListTitle">Select a Template</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <MDBContainer>
        <MDBRow>
          <MDBCol col="4">Name</MDBCol>
          <MDBCol col="8">Message</MDBCol>
          <hr class="mt-2 mb-0" />
        </MDBRow>
        <MDBContainer
          v-for="template in userTemplates"
          :key="template.id"
          class="p-0"
        >
          <MDBRow
            @click="
              selectTemplate(template.id, template.name, template.message)
            "
            class="template-row"
          >
            <MDBCol col="4" class="mt-2 mb-2">
              {{ template.name }}
            </MDBCol>
            <MDBCol col="8" class="mt-2 mb-2 text-truncate">
              {{ template.message }}
            </MDBCol>
            <hr class="template-divider" />
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </MDBModalBody>
  </MDBModal>
</template>

<script>
import {
  MDBBtn,
  MDBSpinner,
  MDBContainer,
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdb-vue-ui-kit";
import ShowingCard from "../components/ShowingCard.vue";
import NewShowingForm from "../components/showings/NewShowingForm.vue";
import TwilioModalBody from "../components/TwilioModalBody.vue";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Quill from "../components/Quill.vue";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
// import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Listbox from "primevue/listbox";
import OverlayPanel from "primevue/overlaypanel";
import InputNumber from "primevue/inputnumber";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { userSMSTemplates, publicSMSTemplates } from "../services/templates";

// import { Showing, showings, InvalidShowing } from "../services/showings";
import { showings } from "../services/showings";
import { propertyGroups } from "../services/propertyGroups";
import { User } from "../services/users";
import { userEventTypes, Calendly } from "../services/calendly";

import Handlebars from "handlebars";

export default {
  components: {
    MDBBtn,
    MDBSpinner,
    MDBContainer,
    MDBModal,
    MDBModalTitle,
    MDBModalHeader,
    MDBModalBody,
    MDBInput,
    MDBRow,
    MDBCol,
    ShowingCard,
    TwilioModalBody,
    Calendar,
    Button,
    Quill,
    Dialog,
    MultiSelect,
    Dropdown,
    // InputText,
    Checkbox,
    Listbox,
    OverlayPanel,
    InputNumber,
    Accordion,
    AccordionTab,
    NewShowingForm,
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      showingsTexts: false,
      templateList: false,
      newTemplate: false,
      editTemplate: false,
      templateId: null,
      templateMessage: null,
      templateName: null,
      isConnecting: false,
      textShowingOptions: [],
      textShowings: [],
      placeholders: {},
      smallScreen: false,
      // newShowing: {
      //   firstName: null,
      //   lastName: null,
      //   email: null,
      //   phone: null,
      //   propertyGroupId: null,
      //   time: null,
      //   walkIn: false,
      //   start: null,
      //   end: null,
      // },
      start: {},
      end: {},
      time: null,
      newShowingModal: false,
      timeSelected: false,
      isWalkIn: false,
      calendarEvents: [],
      loadingTimes: false,
      prevDay: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      showingDate: null,
      duration: 15,
      buffer: 5,
      startIncrement: 15,
      selectedEventType: null,
      loadingEventTypes: true,
    };
  },
  created() {
    const paramsDate = new Date(this.$route.params.date);
    this.showingDate = new Date(paramsDate.getTime() + this.timeZoneOffset);
  },
  async mounted() {
    await this.loadShowings();
    await this.$store.dispatch("getCalendarSubscription");
    await propertyGroups.loadAll();
    await this.$store.dispatch("loadIntranetSettings");
    await Calendly.loadUserEventTypes();
    this.loadingEventTypes = false;

    window.addEventListener("resize", this.setSmallScreen);
    window.addEventListener("message", async (e) => {
      if (
        this.isCalendlyEvent(e) &&
        e.data.event === "calendly.event_scheduled"
      ) {
        await this.loadShowings();
        this.cancelNewWalkIn();
      }
    });
  },
  watch: {
    $route() {
      this.loadShowings();
    },
    isWalkIn(val) {
      if (val) {
        let showingDate = new Date(this.showingDate);
        const isoShowing = this.getISOString(showingDate);
        if (isoShowing === this.today) {
          showingDate = new Date();
        } else {
          showingDate.setHours(new Date().getHours());
        }

        // this.newShowing.walkIn = true;
        this.start = {
          dateTime: this.localeToIso(showingDate),
          timeZone: this.timezone,
        };
        this.end = {
          dateTime: this.localeToIso(
            new Date(showingDate.getTime() + this.duration * 60000)
          ),
          timeZone: this.timezone,
        };
        this.time = {
          dateTime: new Date().toISOString(),
          timeZone: "UTC",
        };
      }
    },
  },
  computed: {
    availTimeZones() {
      return new Intl.Locale("en-US").timeZones.map((timezone) => {
        return { timezone };
      });
    },
    timeZoneOffset() {
      const date = new Date().toLocaleString("en-US", {
        timeZone: this.timezone,
        timeZoneName: "shortOffset",
      });
      return parseInt(date.split("-")[1]) * 60 * 60000;
    },
    today() {
      return this.getISOString(new Date());
    },
    showings() {
      return showings.all;
    },
    userTemplates() {
      return userSMSTemplates.templates;
    },
    userPhone() {
      return this.$store.getters.userPhone;
    },
    isCurrentDate() {
      if (this.$route.params.date === this.today) {
        return true;
      }
      return false;
    },
    subscriptionExpired() {
      const sub = this.$store.getters.userCalendarSubscription;
      if (sub && new Date(sub.expiration_date_time) < new Date()) {
        return true;
      } else if (!sub) {
        return true;
      }
      return false;
    },
    propertyGroups() {
      return propertyGroups.visible;
    },
    unavailableTimes() {
      return this.calendarEvents.map((event) => {
        if (event.showAs === "busy") {
          return { start: event.start, end: event.end };
        }
      });
    },
    availableTimes() {
      const times = [];
      const showingStart = new Date(this.showingDate);
      const selectedOffset = this.timeZoneOffset / 3600000;
      const systemOffset = new Date().getTimezoneOffset() / 60;

      const offsetDiff = systemOffset - selectedOffset;

      showingStart.setHours(9 - offsetDiff);
      while (showingStart.getHours() < 17 - offsetDiff) {
        let unavail = this.unavailableTimes.find((time) => {
          let eventStart = new Date(`${time.start.dateTime}Z`).getTime();
          let eventEnd = new Date(`${time.end.dateTime}Z`).getTime();
          eventStart = new Date(eventStart - this.buffer * 60000);
          eventEnd = new Date(eventEnd + this.buffer * 60000);
          let showingEnd = new Date(
            showingStart.getTime() + this.duration * 60000
          );
          if (eventEnd > showingStart && eventStart <= showingStart)
            return true;
          if (showingEnd > eventStart && showingEnd < eventEnd) return true;
          return false;
        });

        let isoShowing = this.getISOString(showingStart);
        if (isoShowing === this.today && new Date() > showingStart)
          unavail = true;

        if (!unavail) {
          times.push({
            date: new Date(showingStart),
            label: new Date(showingStart.toISOString()).toLocaleString(
              "en-US",
              {
                timeStyle: "short",
                timeZone: this.timezone,
              }
            ),
          });
        }
        showingStart.setTime(
          showingStart.getTime() + this.startIncrement * 60000
        );
      }
      return times;
    },
    newShowingDateTime() {
      if (this.isWalkIn) {
        return "Walk-In";
      } else {
        return new Date(this.time.date).toLocaleString("en-US", {
          dateStyle: "long",
          timeStyle: "short",
          timeZone: this.timezone,
        });
      }
    },
    showingsConfig() {
      return this.$store.getters.showingsConfig;
    },
    userEventTypes() {
      return userEventTypes.value;
    },
  },
  methods: {
    isCalendlyEvent(e) {
      return (
        e.origin === "https://calendly.com" &&
        e.data.event &&
        e.data.event.indexOf("calendly.") === 0
      );
    },

    async loadShowings() {
      this.isLoading = true;
      await showings.loadAll(this.$route.params.date);
      this.isLoading = false;
    },

    getISOString(date) {
      const year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return `${year}-${month}-${day}`;
    },

    setSmallScreen() {
      if (window.innerWidth < 576) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    },

    openTextDialog() {
      this.textShowingOptions = this.showings.filter((showing) => {
        if (showing.contact.phone) return showing;
      });
      this.textShowings = this.textShowingOptions;
      this.placeholders = {};
      this.showingsTexts = true;
    },

    changeDate(e) {
      const newDate = `${e.toISOString().slice(0, 10)}`;
      this.$router.replace({ params: { date: newDate } });
    },

    cancelTexts() {
      this.showingsTexts = false;
      this.newTemplate = false;
      this.templateMessage = null;
      this.templateName = null;
      this.editTemplate = false;
    },

    async loadTemplates() {
      await this.$store.dispatch("loadTemplates");
      this.showingsTexts = false;
      this.templateList = true;
      this.editTemplate = false;
      this.newTemplate = false;
    },

    async loadUserTemplates() {
      await userSMSTemplates.loadTemplates();
      this.showingsTexts = false;
      this.templateList = true;
      this.editTemplate = false;
      this.newTemplate = false;
    },

    async loadPublicTemplates() {
      await publicSMSTemplates.loadTemplates();
    },

    selectTemplate(id, name, message) {
      this.templateId = id;
      this.templateName = name;
      this.templateMessage = message;
      this.parseTokens();
      this.templateList = false;
      this.newTemplate = false;
      this.showingsTexts = true;
    },

    parseTokens() {
      this.placeholders = {};
      const parsed = Handlebars.parse(this.templateMessage);
      for (const statement of parsed.body) {
        if (statement.type === "MustacheStatement") {
          const path = statement.path;
          if (path.type === "PathExpression") {
            const original = path.original;
            const parts = original.split(".");
            if (parts[0] === "placeholder") {
              this.placeholders[parts[1]] = null;
            }
          }
        }
      }
    },

    editTemplateClicked() {
      this.editTemplate = true;
      this.newTemplate = false;
    },

    templateUpdated(e) {
      this.templateMessage = e.textValue;
      this.parseTokens();
    },

    checkForm(e) {
      const btnId = e.submitter.id;

      switch (btnId) {
        case "sendTexts":
          this.sendTexts();
          break;
        case "updateAndSend":
          this.updateAndSend();
          break;
        case "createAndSend":
          this.createAndSend();
          break;
      }
    },

    async createAndSend() {
      this.isSending = true;
      try {
        await this.$store.dispatch("createTemplate", {
          name: this.templateName,
          message: this.templateMessage,
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.textShowings.forEach((showing) => {
          if (Object.keys(this.placeholders).length > 0) {
            showing.sendText(this.templateMessage, this.placeholders);
          } else {
            showing.sendText(this.templateMessage);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.showingsTexts = false;
        this.isSending = false;
        this.templateId = null;
        this.templateName = null;
        this.templateMessage = null;
        this.newTemplate = false;
      }
    },

    async updateAndSend() {
      this.isSending = true;
      try {
        await this.$store.dispatch("updateTemplate", {
          id: this.templateId,
          name: this.templateName,
          message: this.templateMessage,
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.textShowings.forEach((showing) => {
          if (Object.keys(this.placeholders).length > 0) {
            showing.sendText(this.templateMessage, this.placeholders);
          } else {
            showing.sendText(this.templateMessage);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.showingsTexts = false;
        this.isSending = false;
        this.templateId = null;
        this.templateName = null;
        this.templateMessage = null;
        this.editTemplate = false;
      }
    },

    async sendTexts() {
      this.isSending = true;
      try {
        this.textShowings.forEach((showing) => {
          if (Object.keys(this.placeholders).length > 0) {
            console.log(this.placeholders);
            showing.sendText(this.templateMessage, this.placeholders);
          } else {
            showing.sendText(this.templateMessage);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.showingsTexts = false;
        this.isSending = false;
        this.templateMessage = null;
        this.templateName = null;
      }
    },

    async reconnectCalendar() {
      this.isConnecting = true;
      await this.$store.dispatch("reconnectCalendarSubscription");
      await this.$store.dispatch("getCalendarSubscription");
      await this.$store.dispatch("loadShowings", {
        showingDate: this.$route.params.date,
      });
      this.isConnecting = false;
    },

    placeholderAdded(e) {
      this.placeholders[e] = null;
    },

    async openNewShowing() {
      this.newShowingModal = true;

      if (!this.showingsConfig.providers.includes("calendly")) {
        const selectedDate = new Date(this.showingDate.toISOString());
        selectedDate.setHours(
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
        if (Date.now() > selectedDate.getTime() + 5 * 60000) {
          this.prevDay = true;
          this.isWalkIn = true;
        } else {
          try {
            this.loadingTimes = true;
            const start = this.showingDate;
            const end = new Date(start.getTime() + 24 * 3600000);

            this.calendarEvents = await User.getCalendarEvents(
              this.showingDate.toISOString(),
              end.toISOString()
            );
            this.loadingTimes = false;
          } catch (error) {
            console.error(error);
          }
        }
      }
    },

    async newShowingCreated() {
      await this.loadShowings();
      this.cancelNewWalkIn();
    },

    cancelNewWalkIn() {
      this.newShowingModal = false;
      this.start = {};
      this.end = {};
      this.time = null;
      this.timeSelected = false;
      this.isWalkIn = false;
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.selectedEventType = null;
    },

    backToTimeSelect() {
      this.timeSelected = false;
      this.isWalkIn = false;
    },

    openTimezoneOverlay(e) {
      this.$refs.timezoneOverlay.toggle(e);
      this.$nextTick(() => {
        const overlay = this.$refs.timezoneOverlay;
        const content = overlay.container.firstChild;

        content.style.padding = 0;
      });
    },

    onStartSelected(e) {
      const start = this.localeToIso(e.value.date);
      const end = this.localeToIso(
        new Date(e.value.date.getTime() + this.duration * 60000)
      );

      this.start = {
        dateTime: start,
        timeZone: this.timezone,
      };
      this.end = {
        dateTime: end,
        timeZone: this.timezone,
      };
      this.timeSelected = true;
    },

    localeToIso(date) {
      const selected = date
        .toLocaleString("en-US", {
          timeZone: this.timezone,
          hour12: false,
        })
        .split(", ");

      const localeDate = selected[0].split("/");
      const time = selected[1];
      const month = parseInt(localeDate[0]).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const day = parseInt(localeDate[1]).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const year = localeDate[2];

      return `${year}-${month}-${day}T${time}.000`;
    },

    async onEventTypeChange() {
      const embed = window.Calendly;
      const element = document.getElementById("calendlyWidget");
      const widgets = element.querySelectorAll("iframe");
      if (widgets.length > 0) {
        for (const widget of widgets) {
          element.removeChild(widget);
        }
      }
      const unit = await Calendly.getEventTypeUnit(this.selectedEventType);
      embed.initInlineWidget({
        url: unit.showing_url + "&hide_event_type_details=1",
        parentElement: element,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/mdb/scss/standard/free/_variables.scss";

.showing-header {
  background-color: $primary;
  color: white;
}

.add-template {
  margin: 0.625rem 1px 0.5rem 1px;
}

.template-row:hover {
  cursor: pointer;
  background-color: $info;
}

.template-divider {
  margin: 0;
}

.template-label {
  font-size: 11px;
  color: $primary;
  margin: 0;
}

.recipients-selector-container {
  // flex: 1 1 auto;
  // min-width: 75%;
  // justify-content: flex-end;
  // display: flex;
  // align-items: flex-end;

  span {
    flex: 1 1 auto;
  }

  :deep(.p-multiselect) {
    flex: 1 1 auto;
  }
}

.send-texts-submit-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: -10px;
}

.send-texts-submit-container > * {
  margin: 10px;
}

.p-float-label > input,
.p-float-label > .p-dropdown {
  width: 100%;
}

.field-checkbox {
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.8s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .gif-container {
    flex: 0 0 50%;

    img {
      max-width: 100%;
    }
  }
}

:deep(.auto-input) {
  input {
    max-width: 2.357rem;
    text-align: center;
  }
}

.variables-table {
  p {
    margin-bottom: 0;
  }

  td {
    text-align: right;
    padding: 0.25rem;
  }
}

// #calendlyWidget {
//   min-height: 50vh;
// }

:deep(#calendlyWidget) > iframe {
  height: 100vh;
}
</style>
