<template>
  <div class="login-container">
    <MDBCard>
      <MDBCardHeader>Welcome</MDBCardHeader>
      <MDBCardBody
        class="d-flex flex-column justify-content-evenly align-items-center"
      >
        <img
          class="logo"
          src="https://f.hubspotusercontent20.net/hubfs/6028923/mpm_logo.png"
        />
        <MDBBtn @click="login" rounded color="secondary">OPEN LOGIN</MDBBtn>
      </MDBCardBody>
    </MDBCard>
  </div>
</template>

<script>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBBtn,
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.$store.dispatch("login");
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: transparent
    linear-gradient(180deg, #c5a459 0%, #ede2b0 69%, #fff9de 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  display: flex;
}

.card {
  width: 30%;
  min-width: 325px;
  height: 30rem;
  text-align: center;
}

.logo {
  width: 250px;
}

.login-btn {
  cursor: pointer;
}
</style>
