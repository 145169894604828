<template>
  <MDBModalBody v-if="!userPhone">
    <div v-if="!searchResults" class="d-flex flex-column align-items-center">
      <p>Search For Number By Areacode</p>
      <div class="d-flex">
        <MDBInput label="Areacode" v-model="areaCode" maxlength="3" required />
        <MDBBtn @click="searchForNumbers" color="primary">Search</MDBBtn>
      </div>
      <MDBSpinner v-if="isLoading" color="primary" class="m-2" />
    </div>
    <div v-else-if="searchResults && !isLoading">
      <a class="d-flex align-items-center" role="button" @click="searchAgain">
        <MDBIcon class="m-2" icon="arrow-left" iconStyle="fas" />
        <p class="m-0">Search Again</p>
      </a>
      <MDBTable>
        <thead>
          <tr class="header-row">
            <th rowspan="2" class="num-header">Number</th>
            <th colspan="3" class="bottom-border">Capabilities</th>
            <th rowspan="2"></th>
          </tr>
          <tr class="header-row">
            <th scope="col">Voice</th>
            <th scope="col">SMS</th>
            <th scope="col">MMS</th>
          </tr>
        </thead>
        <tbody id="numTableBody">
          <tr
            v-for="result in searchResults"
            :key="result.phoneNumber"
            class="result-row"
          >
            <td class="number-cell">
              <p>
                <strong>{{ result.friendlyName }}</strong>
              </p>
              <p>{{ `${result.locality}, ${result.region}` }}</p>
            </td>
            <td>
              <MDBIcon
                v-if="result.capabilities.voice"
                icon="phone-alt"
                iconStyle="fas"
                size="lg"
              />
            </td>
            <td>
              <MDBIcon
                v-if="result.capabilities.SMS"
                icon="sms"
                iconStyle="fas"
                size="lg"
              />
            </td>
            <td>
              <MDBIcon
                v-if="result.capabilities.MMS"
                icon="comment-alt"
                iconStyle="fas"
                size="lg"
              />
            </td>
            <td>
              <MDBBtn
                @click="selectedNumber(result.phoneNumber)"
                color="success"
                >Select</MDBBtn
              >
            </td>
          </tr>
        </tbody>
      </MDBTable>
    </div>
    <div v-else class="d-flex justify-content-center">
      <MDBSpinner color="primary" />
    </div>
  </MDBModalBody>
  <MDBModalBody class="d-flex justify-content-center align-items-center" v-else>
    <p>Your new number is {{ userPhone }}</p>
  </MDBModalBody>
</template>

<script>
import {
  MDBModalBody,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBTable,
  MDBIcon,
} from "mdb-vue-ui-kit";

export default {
  components: {
    MDBModalBody,
    MDBInput,
    MDBBtn,
    MDBSpinner,
    MDBTable,
    MDBIcon,
  },
  data() {
    return {
      areaCode: null,
      isLoading: false,
    };
  },
  computed: {
    searchResults() {
      return this.$store.getters.numberSearchResults;
    },
    userPhone() {
      return this.$store.getters.userPhone;
    },
  },
  methods: {
    async searchForNumbers() {
      this.isLoading = true;
      await this.$store.dispatch("searchForNumbers", this.areaCode);
      this.isLoading = false;
    },
    searchAgain() {
      this.$store.commit("setSearchResults", null);
    },
    async selectedNumber(num) {
      this.isLoading = true;
      await this.$store.dispatch("purchaseNumber", num);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.number-cell p {
  margin: 0;
  text-align: left !important;
}

.result-row {
  text-align: center;
}

.header-row {
  text-align: center;
  background-color: #eee;
}
</style>
