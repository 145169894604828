import axios from "axios";
import { openOAuth } from "./store";

export default {
  state() {
    return {
      apps: {
        hubspot: {
          imageUrl:
            "https://f.hubspotusercontent20.net/hubfs/6028923/hubspotlogo-web-color.png",
          status: "disconnected",
          appName: "hubspot",
        },
        pandadoc: {
          imageUrl:
            "https://f.hubspotusercontent20.net/hubfs/6028923/PandaDoc_Logo_PNG.png",
          status: "disconnected",
          appName: "pandadoc",
        },
        zoom: {
          imageUrl:
            "https://cdn.maxxpm.com/intranet/integrations/Zoom_Logo_Blue.png",
          status: "disconnected",
          appName: "zoom",
        },
        bitly: {
          imageUrl:
            "https://cdn.maxxpm.com/intranet/integrations/bitly-logo.png",
          status: "disconnected",
          appName: "bitly",
        },
        jotform: {
          imageUrl:
            "https://www.jotform.com/resources/assets/logo-nb/min/jotform-logo-transparent-400x200.png",
          status: "disconnected",
          appName: "jotform",
        },
        rently: {
          imageUrl: "https://cdn.maxxpm.com/intranet/integrations/rently.png",
          status: "connected",
          appName: "rently",
        },
        Calendly: {
          imageUrl: "https://cdn.maxxpm.com/intranet/integrations/calendly.svg",
          status: "disconnected",
          appName: "Calendly",
        },
        leadsimple: {
          imageUrl:
            "https://cdn.maxxpm.com/intranet/integrations/leadsimple.svg",
          status: "disconnected",
          appName: "leadsimple",
          bg: "#16283B",
        },
      },
    };
  },
  actions: {
    connectHubspot() {
      openOAuth(
        `${process.env.VUE_APP_API_URL}/install/hubspot`,
        "hubspot",
        800,
        800
      );
    },

    connectZoom() {
      openOAuth(
        `${process.env.VUE_APP_API_URL}/install/zoom`,
        "zoom",
        800,
        800
      );
    },

    connectBitly() {
      openOAuth(
        `${process.env.VUE_APP_API_URL}/install/bitly`,
        "bitly",
        800,
        800
      );
    },

    async loadIntegrations(context) {
      const integrations = await axios.get(
        `${process.env.VUE_APP_API_URL}/integrations`,
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      context.commit("setApps", integrations.data);
    },

    async updateIntegration(context, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/integrations`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        if (res.data.app_name) {
          context.commit("addIntegration", {
            appName: res.data.app_name,
            status: "connected",
          });
        }
      } catch (error) {
        if (error.isAxiosError) {
          console.log(error.response);
        }
        throw error;
      }
    },

    async updateRently(context, payload) {
      try {
        const formData = new FormData();
        formData.append("rently_csv", payload, payload.name);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/integrations/rentlyupload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (error) {
        if (error.isAxiosError) {
          console.log(error.response);
        }
        throw error;
      }
    },
  },
  mutations: {
    addIntegration(state, payload) {
      state.apps[payload.appName].status = payload.status;
    },
    setApps(state, payload) {
      state.apps = payload;
    },
  },
  getters: {
    apps(state) {
      return state.apps;
    },
  },
};
