import axios from "axios";
import store from "../store/store.js";
import { reactive } from "vue";

export class Lease {
  id;
  unit_id;
  property_id;
  pm_software_link;
  rent;
  last_renewal;
  signed_date;
  start_date;
  end_date;
  created_at;
  updated_at;
  tenants;
  unit;
  pm_software_id;

  constructor(databaseRecord) {
    for (const [key, value] of Object.entries(databaseRecord)) {
      this[key] = value;
    }
  }
}

export default class Leases {
  request;
  data;

  constructor() {
    this.request = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/reports`,
      headers: {
        Authorization: `Bearer ${store.getters.token}`,
      },
    });
    this.data = reactive([]);
  }

  async load() {
    try {
      this.data.value = [];
      const res = await this.request.get("/lease-expirations");
      for (const lease of res.data) {
        this.data.value.push(new Lease(lease));
      }
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }
}

// export default new Leases();
