import { reactive } from "vue";
import axios from "axios";
import store from "../store/store";

export class PropertyGroup {
  id;
  availability_url;
  display_city;
  display_name;
  display_state;
  latitude_reference;
  logo_url;
  longitude_reference;
  pm_software_id;
  property_group_name;
  user_id;
  visible;
  created_at;
  updated_at;
  properties;
  property_manager;
  showings;
  units;
  applications;
  applicationDetails;
  unitDetails;
  active;

  async save() {
    try {
      this._checkInputs();

      await axios.post(`${process.env.VUE_APP_API_URL}/property-groups`, this, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async update() {
    try {
      this._checkInputs();

      await axios.put(`${process.env.VUE_APP_API_URL}/property-groups/${this.id}`, this, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async delete() {
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/property-groups/${this.id}`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  _checkInputs() {
    if (this.visible && (!this.property_group_name || !this.display_city || !this.display_state)) {
      let invalidInputs = Object.entries(this).filter(([key, value]) => (key === "display_name" || key === "display_city" || key === "display_state") && !value);
      invalidInputs = invalidInputs.map(([key]) => key);
      throw new PropertyGroupValidationError(invalidInputs, "Missing required field(s)");
    }
  }

  static async getSchema() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/schema/property-group`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      const schema = [];

      res.data.forEach((column) => {
        const value = column[1];
        if (value.meta && value.meta.columnLabel) {
          schema.push(value);
        }
      });

      return schema;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  //eslint-disable-next-line
  set active(active) {
    this.active = active;
  }

  async loadMapDetails() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/maps/property-group/${this.id}`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      this.units = res.data.units;
      this.applications = res.data.applications;
      this.unitDetails = res.data.unitDetails;
      this.applicationDetails = res.data.applicationDetails;
      this.active = true;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }
}

export class PropertyGroupValidationError extends Error {
  constructor(invalidInputs, message = null) {
    super(message);

    this.invalidInputs = invalidInputs;
  }
}

export const propertyGroups = reactive({
  all: [],
  visible: [],
  async loadAll() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/property-groups`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      const _propertyGroups = res.data.map((propertyGroup) => {
        const _propertyGroup = new PropertyGroup();

        _propertyGroup.id = propertyGroup.id;
        _propertyGroup.property_group_name = propertyGroup.property_group_name;
        _propertyGroup.display_name = propertyGroup.display_name;
        _propertyGroup.display_city = propertyGroup.display_city;
        _propertyGroup.display_state = propertyGroup.display_state;
        _propertyGroup.longitude_reference = propertyGroup.longitude_reference;
        _propertyGroup.latitude_reference = propertyGroup.latitude_reference;
        _propertyGroup.logo_url = propertyGroup.logo_url;
        _propertyGroup.user_id = propertyGroup.user_id;
        _propertyGroup.created_at = propertyGroup.created_at;
        _propertyGroup.updated_at = propertyGroup.updated_at;
        _propertyGroup.property_manager = propertyGroup.property_manager;
        _propertyGroup.properties = propertyGroup.properties;
        _propertyGroup.applications = propertyGroup.applications;
        _propertyGroup.units = propertyGroup.units;
        _propertyGroup.availability_url = propertyGroup.availability_url;
        _propertyGroup.visible = propertyGroup.visible === 1 ? true : false;
        _propertyGroup.unitDetails = propertyGroup.unitDetails;
        _propertyGroup.applicationDetails = propertyGroup.applicationDetails;
        _propertyGroup.active = false;

        return _propertyGroup;
      });

      this.all = _propertyGroups;
      this.visible = _propertyGroups.filter((group) => group.visible);
      this.visible = this.visible.sort((a, b) => a.display_city.localeCompare(b.display_city));
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  },
});
