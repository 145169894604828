<template>
  <login v-if="!isLoggedIn" />
  <nav-bar v-if="isLoggedIn" />
  <div class="app-content">
    <router-view v-if="isLoggedIn && hasToken" :key="$route.path" />
  </div>
  <SaveModal v-model:visible="proxyLogin" :showHeader="false" :showCloseIcon="false" :modal="false">
    <div class="d-flex align-items-center">
      <span>Logged in as {{ proxyFullname }}</span>
      <Button label="Logout" class="p-button-rounded ms-4" @click="proxyLogout" />
    </div>
  </SaveModal>
</template>

<script>
import Login from "./views/Login.vue";
import { register } from "register-service-worker";
import axios from "axios";
import SaveModal from "./components/SaveModal.vue";
import Button from "primevue/button";

export default {
  components: {
    Login,
    SaveModal,
    Button,
  },
  data() {
    return {
      registration: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    hasToken() {
      if (this.$store.getters.token !== null) {
        return true;
      }

      return false;
    },
    proxyLogin() {
      if (this.$store.getters.proxyFullname) {
        return true;
      }
      return false;
    },
    proxyFullname() {
      return this.$store.getters.proxyFullname;
    },
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue) {
        if (!("serviceWorker" in navigator)) {
          return;
        }

        if (!("PushManager" in window)) {
          return;
        }

        if (process.env.NODE_ENV === "production") {
          this.askPermission();
        }
      }
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  mounted() {
    const self = this;
    if (process.env.NODE_ENV === "production") {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
          console.log(
            "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
          );
        },
        async registered(registration) {
          console.log("Service worker has been registered.");
          self.registration = registration;
        },
        cached() {
          console.log("Content has been cached for offline use.");
        },
        updatefound() {
          console.log("New content is downloading.");
        },
        updated() {
          console.log("New content is available; please refresh.");
        },
        offline() {
          console.log("No internet connection found. App is running in offline mode.");
        },
        error(error) {
          console.error("Error during service worker registration:", error);
        },
      });

      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("message", async (e) => {
          if (e.data.clickEvent) {
            await this.$store.dispatch("loadContact", e.data.contactId);
            self.$router.push(`/conversations/${e.data.contactId}`);
          } else {
            self.$store.dispatch("loadUserConversations");
            const audio = new Audio("https://dn8z55bp0g7x7.cloudfront.net/notification.mp3");
            audio.play();
          }
        });
      }
    }
  },
  beforeUnmount() {
    this.$store.dispatch("logout");
  },
  methods: {
    async askPermission() {
      const self = this;
      const permissionResult = await Notification.requestPermission();

      if (permissionResult !== "granted") {
        throw new Error("Permission not granted");
      } else {
        const subscription = await self.registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: self.urlBase64ToUint8Array(process.env.VUE_APP_VAPID_KEY),
        });

        await axios.post(`${process.env.VUE_APP_API_URL}/subscribe`, JSON.stringify(subscription), {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${self.$store.getters.token}`,
          },
        });
      }
    },
    urlBase64ToUint8Array(base64String) {
      var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

      var rawData = window.atob(base64);
      var outputArray = new Uint8Array(rawData.length);

      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    proxyLogout() {
      this.$store.dispatch("proxyLogout");
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";

#app {
  // font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: Red Hat Display, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100vh;
}

.app-content {
  flex: 1 1 auto;
  // align-self: stretch;
}
</style>
