<template>
  <Toast />
  <MDBCard class="showing-card border">
    <div class="d-flex justify-content-between p-3 card-header">
      <div class="d-flex align-items-center">
        <MDBCardTitle class="mb-0">
          {{ contactFullName }}
        </MDBCardTitle>
        <a v-if="showing.contact.phone" role="button" @click="goToContact">
          <i
            v-if="unread"
            class="pi pi-comments text-info ms-2"
            style="font-size: 1.5rem"
            v-badge.danger="unread"
          ></i>
          <i
            v-else
            class="pi pi-comments text-info ms-2"
            style="font-size: 1.5rem"
          ></i>
        </a>
      </div>
      <MDBCardTitle class="mb-0">
        {{ showing.getStartTime() }}
      </MDBCardTitle>
    </div>
    <hr class="showing-hr" />
    <MDBContainer
      :class="`${
        showing.checked_in === 1
          ? 'bg-success text-white'
          : showing.checked_in === 0
          ? 'bg-danger text-white'
          : showing.meeting_confirmed
          ? 'bg-info text-white'
          : ''
      } card-info`"
    >
      <MDBRow class="justify-content-between mb-2">
        <MDBCol
          class="contact-info mt-1 mb-1 flex-column justify-content-center"
        >
          <MDBRow class="pt-1 pb-1">
            <MDBCol col="12" md="2">
              <p class="card-text text-title">Phone:</p>
            </MDBCol>
            <MDBCol col="12" md="10">
              <p class="card-text">{{ contact.phone }}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow class="pt-1 pb-1">
            <MDBCol col="12" md="2">
              <p class="card-text text-title">Email:</p>
            </MDBCol>
            <MDBCol col="12" md="10">
              <p class="card-text">{{ contact.email }}</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol class="buttons d-flex align-items-center justify-content-end">
          <MDBRow v-if="showing.checked_in === null" class="'gy-1 mt-1 mb-1'">
            <MDBCol
              col="12"
              lg="4"
              class="d-flex justify-content-center align-items-center mb-1 gy-2"
            >
              <Skeleton
                v-show="updating"
                :width="confirmWidth"
                :height="confirmHeight"
                borderRadius=".25rem"
              />
              <MDBBtn
                v-show="showing.meeting_confirmed === null && !updating"
                @click="confirm"
                color="info"
                :disabled="isFutureDate"
                ref="confirmBtn"
              >
                CONFIRM
              </MDBBtn>
              <p
                v-show="showing.meeting_confirmed && !updating"
                class="text-white mb-0"
              >
                CONFIRMED
              </p>
            </MDBCol>
            <MDBCol
              col="12"
              lg="4"
              class="d-flex justify-content-center align-items-center mb-1 gy-2"
            >
              <Skeleton
                v-show="updating"
                :width="checkInWidth"
                :height="checkInHeight"
                borderRadius=".25rem"
              />
              <MDBBtn
                v-show="!updating"
                @click="checkIn"
                color="success"
                :disabled="isFutureDate"
                class="text-nowrap"
                ref="checkInBtn"
              >
                CHECK IN
              </MDBBtn>
            </MDBCol>
            <MDBCol
              col="12"
              lg="4"
              class="d-flex justify-content-center align-items-center mb-1 gy-2"
            >
              <Skeleton
                v-show="updating"
                :width="noShowWidth"
                :height="noShowHeight"
                borderRadius=".25rem"
              />
              <MDBBtn
                v-show="!updating"
                @click="noShow"
                color="danger"
                :disabled="isFutureDate"
                class="text-nowrap"
                ref="noShowBtn"
              >
                NO SHOW
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow v-else>
            <MDBCol>
              <p v-if="showing.checked_in === 1" class="checked-in mb-0">
                CHECKED IN
              </p>
              <p v-else class="no-show mb-0">NO SHOW</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </MDBCard>
</template>

<script>
import {
  MDBCard,
  MDBCardTitle,
  MDBBtn,
  MDBContainer,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";
import Skeleton from "primevue/skeleton";
import Toast from "primevue/toast";
import { DateTime } from "luxon";
import { showings } from "../services/showings";

export default {
  props: {
    showing: null,
  },
  components: {
    MDBCard,
    MDBCardTitle,
    MDBBtn,
    MDBContainer,
    MDBCol,
    MDBRow,
    Skeleton,
    Toast,
  },
  data() {
    return {
      unread: null,
      updating: false,
      confirmWidth: "100%",
      confirmHeight: "1rem",
      noShowWidth: "100%",
      noShowHeight: "1rem",
      checkInWidth: "100%",
      checkInHeight: "1rem",
    };
  },
  async mounted() {
    const unreadMessages = this.contact.smsMessages.filter(
      (message) => message.read === 0
    );

    if (unreadMessages.length > 0) {
      this.unread = unreadMessages.length;
    }

    this.$nextTick(() => {
      this.confirmCalc();
      this.noShowCalc();
      this.checkInCalc();
    });
  },
  computed: {
    contact() {
      return this.showing.contact;
    },
    contactFullName() {
      return (
        this.showing.contact.first_name + " " + this.showing.contact.last_name
      );
    },
    isFutureDate() {
      const showingDate = DateTime.fromISO(this.showing.start_time);
      if (
        showingDate.toFormat("yyyy-MM-dd") >
        DateTime.now().toFormat("yyyy-MM-dd")
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async checkIn() {
      this.updating = true;
      try {
        await this.showing.checkIn();
        await showings.loadAll(this.$route.params.date);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Check In Failed",
          detail: `${this.contactFullName} was not checked in. Please try again later.`,
          life: 6000,
        });
        console.error(error);
      } finally {
        this.updating = false;
      }
    },

    async noShow() {
      try {
        this.updating = true;
        await this.showing.noShow();
        await showings.loadAll(this.$route.params.date);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "No Show Failed",
          detail: `Error for ${this.contactFullName} occured. Please try again later.`,
          life: 6000,
        });
        console.error(error);
      } finally {
        this.updating = false;
      }
    },

    async confirm() {
      this.updating = true;
      try {
        await this.showing.confirm();
        await showings.loadAll(this.$route.params.date);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Confirm Failed",
          detail: `Error for ${this.contactFullName} occured. Please try again later.`,
          life: 6000,
        });
        console.error(error);
      } finally {
        this.updating = false;
      }
      // this.$store.dispatch("confirmShowing", { id, date });
    },

    async goToContact() {
      await this.$store.dispatch("loadContact", this.showing.contact_id);
      this.$router.push({
        name: "ContactConversation",
        params: { id: this.showing.contact_id },
      });
    },
    confirmCalc() {
      if (this.$refs.confirmBtn) {
        const values = this.$refs.confirmBtn.$el.getBoundingClientRect();
        this.confirmWidth = `${values.width}px`;
        this.confirmHeight = `${values.height}px`;
      }
    },
    checkInCalc() {
      if (this.$refs.checkInBtn) {
        const values = this.$refs.checkInBtn.$el.getBoundingClientRect();
        this.checkInWidth = `${values.width}px`;
        this.checkInHeight = `${values.height}px`;
      }
    },
    noShowCalc() {
      if (this.$refs.noShowBtn) {
        const values = this.$refs.noShowBtn.$el.getBoundingClientRect();
        this.noShowWidth = `${values.width}px`;
        this.noShowHeight = `${values.height}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showing-card {
  flex-grow: 1;
  min-width: 300px;
  margin-bottom: 25px;
}

.card-header {
  background: #f3f3f3;
  border-bottom: none;
}

.showing-hr {
  margin: 0;
}

.contact-info {
  flex: 1 0 0% !important;
  display: flex;
}

.buttons {
  flex: 1 0 0% !important;
}

.card-info {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

:deep(.card-text) {
  white-space: nowrap;
}

:deep(.text-title) {
  text-align: right;
}

@media screen and (max-width: 768px) {
  :deep(.text-title) {
    text-align: left;
  }
}
</style>
