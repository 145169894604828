import { createApp } from "vue";
import router from "./router/index";
import App from "./App.vue";
import NavBar from "./components/NavBar.vue";
import store from "./store/store";
import PrimeVue from "primevue/config";
import BadgeDirective from "primevue/badgedirective";
import "./registerServiceWorker";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";

// import "./assets/css/theme.css"; //primevue theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./assets/css/main.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

const app = createApp(App);

app.use(PrimeVue);
app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);
app.use(ToastService);
app.use(ConfirmationService);

app.component("nav-bar", NavBar);
app.component("Toast", Toast);

app.use(router).use(store).mount("#app");
