<template>
  <div class="forbidden-container">
    <error-card
      :errorCode="403"
      errorTitle="OPPS! ACCESS DENIED"
      errorMsg="Sorry, you don't have access to this page."
      btnText="RETURN HOME"
      href="/"
    />
  </div>
</template>

<script>
import ErrorCard from "../../components/ErrorCard.vue";

export default {
  components: {
    ErrorCard,
  },
};
</script>

<style scoped>
.forbidden-container {
  justify-content: center;
  align-items: center;
  background: transparent
    linear-gradient(180deg, #c5a459 0%, #ede2b0 69%, #fff 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  display: flex;
}
</style>
