import { reactive } from "vue";
import axios from "axios";
import store from "../store/store";

export class User {
  id;
  firstname;
  lastname;
  email;
  ms_id;
  twilio_number;
  remember_me_token;
  is_admin;
  zoom_number;
  zoom_extension;
  zoom_id;
  is_management;
  created_at;
  updated_at;
  fullname;
  ms_subscriptions;
  showings;
  sms_messages;
  api_tokens;
  socket_ids;
  templates;
  job_title;
  push_subscription;
  property_groups;
  microsoft_account;
  token;
  bio;
  image;

  constructor(id) {
    this.id = id;
  }

  static async getSchema() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/schema/user`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const schema = [];
      res.data.forEach((column) => {
        if (column[1].meta && column[1].meta.columnLabel) {
          schema.push(column[1]);
        }
      });

      return schema;
    } catch (error) {
      console.error(error);
    }
  }

  static async getCalendarEvents(start, end) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/calendar/events?startDateTime=${start}&endDateTime=${end}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async delete() {
    try {
      const res = await axios.delete(
        `${process.env.VUE_APP_API_URL}/users/${this.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  fill(user) {
    for (const [key, value] of Object.entries(user)) {
      this[key] = value;
    }
  }

  async update(payload) {
    const res = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/${this.id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      }
    );

    return res.data;
  }

  getUpdatePayload(newData) {
    return Object.keys(newData).reduce((payload, key) => {
      if (this[key] === newData[key]) {
        return payload
      }

      payload[key] = newData[key]
      return payload  
    }, {})
  }
}

export const UsersStore = reactive({
  users: [],
  async loadUsers() {
    try {
      UsersStore.users = [];
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      for (const user of response.data) {
        const _user = new User(user.id);
        _user.fill(user);

        UsersStore.users.push(_user);
      }
    } catch (error) {
      console.log(error);
    }
  },
});
