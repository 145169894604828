<template>
  <div class="d-flex justify-content-center m-3">
    <h1>{{ `Welcome ${name}!` }}</h1>
  </div>
  <div class="d-flex">
    <MDBCard class="m-4">
      <MDBCardHeader>
        <MDBCardTitle class="text-secondary">Important Links</MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody class="d-flex flex-column">
        <MDBBtn tag="a" color="link" href="/knowledge" target="_blank">
          MAXX PM Employee Knowledge Base
          <i class="fas fa-external-link-alt"></i>
        </MDBBtn>
        <MDBBtn tag="a" color="link" href="/company-directory"> Company Directory </MDBBtn>
        <MDBBtn tag="a" color="link" href="https://maxxpm.appfolio.com" target="_blank">
          AppFolio
          <i class="fas fa-external-link-alt"></i>
        </MDBBtn>
      </MDBCardBody>
    </MDBCard>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="flex-grow-1 report-header">
        <h5 class="card-title text-secondary text-center w-100">Upcoming Lease Expirations</h5>
        <span class="report-refresh">
          <a href="#" @click="refreshLeases" :class="{ disabled: refreshingLeases }">
            <i class="fas fa-sync-alt" :class="{ 'pi-spin': refreshingLeases }"></i>
          </a>
        </span>
      </div>
      <Chart
        v-if="leaseData.datasets[0] && leaseData.datasets[0].data.length > 0"
        type="bar"
        :data="leaseData"
        :width="700"
        :height="400"
        @select="leaseMonthSelected" />
      <Skeleton v-else width="700px" height="400px" />

      <Dialog v-model:visible="leasesModal" modal>
        <DataTable :value="selectedLeases">
          <Column field="unit.unit_name" header="Unit">
            <template #body="{ data }">
              <a :href="unitHref(data)" target="_blank">{{ data.unit.unit_name }}</a>
            </template>
          </Column>
          <Column field="tenants" header="Tenants">
            <template #body="{ data }">
              <a :href="getTenantNames(data).href" target="_blank">
                {{ getTenantNames(data).tenants }}
              </a>
            </template>
          </Column>
          <Column field="lease_from" header="Lease From">
            <template #body="{ data }">
              {{
                new Date(data.lease_from).toLocaleString("en-US", {
                  dateStyle: "short",
                })
              }}
            </template>
          </Column>
          <Column field="lease_to" header="Lease To">
            <template #body="{ data }">
              {{
                new Date(data.lease_to).toLocaleString("en-US", {
                  dateStyle: "short",
                })
              }}
            </template>
          </Column>
          <Column field="status" header="Status" />
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-vue-ui-kit";
import Chart from "primevue/chart";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";
import Leases from "../services/leases";

export default {
  components: {
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    Chart,
    Dialog,
    DataTable,
    Column,
    Skeleton,
  },
  data() {
    return {
      groupedLeases: {},
      selectedLeases: null,
      leasesModal: false,
      socket: null,
      refreshingLeases: false,
      leases: new Leases(),
    };
  },
  computed: {
    name() {
      return this.$store.getters.userFirstName;
    },
    leaseData() {
      const leaseData = {
        labels: [],
        datasets: [],
      };
      this.leases.data.value.forEach((lease) => {
        const month = new Date(lease.lease_to).toLocaleString("en-US", {
          month: "long",
        });
        let mIndex = leaseData.labels.findIndex((m) => m === month);
        if (mIndex < 0) {
          const length = leaseData.labels.push(month);
          mIndex = length - 1;
          this.groupedLeases[month] = {
            reportIndex: mIndex,
            leases: [],
          };
        }
        this.groupedLeases[month].leases.push(lease);
        let sIndex = leaseData.datasets.findIndex((s) => s.label === lease.status);
        if (sIndex < 0) {
          const length = leaseData.datasets.push({
            label: lease.status,
            backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
          });
          sIndex = length - 1;
        }
        if (!leaseData.datasets[sIndex].data) {
          leaseData.datasets[sIndex].data = [];
        }
        leaseData.datasets[sIndex].data[mIndex] =
          leaseData.datasets[sIndex].data[mIndex] > 0 ? leaseData.datasets[sIndex].data[mIndex] + 1 : 1;
      });
      return leaseData;
    },
  },
  async created() {
    try {
      await this.leases.load();
    } catch (error) {
      console.error(error);
    }
  },
  unmounted() {
    if (this.socket !== null) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    }
  },
  methods: {
    leaseMonthSelected(e) {
      for (const month in this.groupedLeases) {
        if (this.groupedLeases[month].reportIndex === e.element.index) {
          this.selectedLeases = this.groupedLeases[month].leases;
        }
      }

      this.leasesModal = true;
    },
    getTenantNames(lease) {
      let tenants = "";
      lease.tenants.forEach((tenant) => {
        tenants += `${tenant.tenant_first_name} ${tenant.tenant_last_name}, `;
      });
      tenants = tenants.replace(/,\s*$/, "");
      return {
        tenants,
        href: `https://maxxpm.appfolio.com/occupancies/${lease.appfolio_id}`,
      };
    },
    unitHref(lease) {
      const baseUrl = "https://maxxpm.appfolio.com/properties";
      if (lease.unit.property.property_type === "Multi-Family") {
        return `${baseUrl}/${lease.unit.property.appfolio_id}/units/${lease.unit.appfolio_id}`;
      }

      return `${baseUrl}/${lease.unit.property.appfolio_id}`;
    },
    async refreshLeases() {
      this.refreshingLeases = true;
      try {
        await this.leases.load();
        this.$toast.removeGroup("leases");
      } catch (error) {
        console.log(error);
      } finally {
        this.refreshingLeases = false;
      }
    },
  },
};
</script>

<style scoped>
.report-header {
  position: relative;
  width: 100%;
}

.report-refresh {
  position: absolute;
  top: 0;
  right: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.75;
}
</style>
