import { reactive } from "vue";
import axios from "axios";
import store from "../store/store";

export class Template {
  constructor(name, message) {
    this.name = name;
    this.message = message;
  }

  set id(val) {
    this._id = val;
  }

  get id() {
    return this._id;
  }

  set private(val) {
    this._private = val === 1 ? true : false;
  }

  get private() {
    return this._private;
  }

  set user(val) {
    this._user = val;
  }

  get user() {
    return this._user;
  }

  set folder(val) {
    this._folder = val;
  }

  get folder() {
    return this._folder;
  }

  set created_at(val) {
    this._createdAt = val;
  }

  get created_at() {
    return this._createdAt;
  }

  set updated_at(val) {
    this._updatedAt = val;
  }

  get updated_at() {
    return this._updatedAt;
  }

  set user_id(val) {
    this._userId = val;
  }

  get user_id() {
    return this._userId;
  }

  set folder_id(val) {
    this._folderId = val;
  }

  get folder_id() {
    return this._folderId;
  }

  get toJSON() {
    const json = {
      name: this.name,
      message: this.message,
      template_type: this.template_type,
    };
    if (this.id) json.id = this.id;
    if (this.private) json.private = this.private;
    if (this.user) json.user = this.user;
    if (this.folder) json.folder = this.folder;
    if (this.created_at) json.created_at = this.created_at;
    if (this.updated_at) json.updated_at = this.updated_at;
    if (this.user_id) json.user_id = this.user_id;
    if (this.folder_id) json.folder_id = this.folder_id;
    return json;
  }

  static async loadTemplate(templateId) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/templates/${templateId}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      let template;
      switch (res.data.template_type) {
        case "SMS":
          template = new SMSTemplate(res.data.name, res.data.message);
          template.id = res.data.id;
          template.private = res.data.private;
          template.user = res.data.user;
          template.folder = res.data.folder;
          template.created_at = res.data.created_at;
          template.updated_at = res.data.updated_at;
          break;
        case "EMAIL":
          template = new EmailTemplate(res.data.name, res.data.message);
          template.id = res.data.id;
          template.private = res.data.private;
          template.user = res.data.user;
          template.folder = res.data.folder;
          template.created_at = res.data.created_at;
          template.updated_at = res.data.updated_at;
          break;
      }

      return template;
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  }

  static async loadAllTemplates() {
    const _templates = [];
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/templates`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      for (const template of res.data) {
        let _template;
        switch (template.template_type) {
          case "SMS":
            _template = new SMSTemplate(template.name, template.message);
            _template.id = template.id;
            _template.private = template.private;
            _template.user = template.user;
            _template.folder = template.folder;
            _template.created_at = template.created_at;
            _template.updated_at = template.updated_at;
            break;
          case "EMAIL":
            _template = new EmailTemplate(template.name, template.message);
            _template.id = template.id;
            _template.private = template.private;
            _template.user = template.user;
            _template.folder = template.folder;
            _template.created_at = template.created_at;
            _template.updated_at = template.updated_at;
            break;
        }
        _templates.push(_template);
      }
      allTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
    }
  }

  async moveToFolder(folder_id) {
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_API_URL}/templates/${this.id}`,
        { folder_id },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update() {
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_API_URL}/templates/${this.id}`,
        this.toJSON,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      return await Template.loadTemplate(res.data.id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async save() {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_API_URL}/templates`,
        this.toJSON,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      return await Template.loadTemplate(res.data.id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async delete() {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/templates/${this.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    } finally {
      await allTemplates.loadTemplates();
    }
  }

  // async send() {
  //   try {
  //     await axios.post(
  //       `${process.env.VUE_APP_API_URL}/send_test`,
  //       { message: this.message },
  //       { headers: { Authorization: `Bearer ${store.getters.token}` } }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }
}

export class EmailTemplate extends Template {
  template_type = "EMAIL";

  constructor(name, message) {
    super(name, message);
  }

  static async loadTemplates() {
    const _templates = [];
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/templates?type=EMAIL`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      res.data.forEach((template) => {
        const _template = new EmailTemplate(template.name, template.message);
        _template.id = template.id;
        _template.private = template.private;
        _template.user = template.user;
        _template.folder = template.folder;
        _template.created_at = template.created_at;
        _template.updated_at = template.updated_at;
        _templates.push(_template);
      });

      smsTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async loadAllTemplates() {
    const _templates = [];
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/email-templates`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      for (const template of res.data) {
        const _template = new EmailTemplate(template.name, template.message);
        _template.id = template.id;
        _template.private = template.private;
        _template.createdAt = template.created_at;
        _template.updatedAt = template.updated_at;
        _template.user = template.user;
        _template.folder = template.folder;
        _templates.push(_template);
      }
      emailTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
    }
  }
}

export class SMSTemplate extends Template {
  template_type = "SMS";

  constructor(name, message) {
    super(name, message);
  }

  static async loadTemplates() {
    const _templates = [];
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/templates?type=SMS`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      res.data.forEach((template) => {
        const _template = new SMSTemplate(template.name, template.message);
        _template.id = template.id;
        _template.private = template.private;
        _template.user = template.user;
        _template.folder = template.folder;
        _template.created_at = template.created_at;
        _template.updated_at = template.updated_at;
        _templates.push(_template);
      });

      smsTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async loadUserTemplates() {
    const _templates = [];
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${store.getters.userId}/templates?type=SMS`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      for (const template of res.data) {
        const _template = new SMSTemplate(template.name, template.message);
        _template.id = template.id;
        _template.private = template.private;
        _template.user = template.user;
        _template.folder = template.folder;
        _template.createdAt = template.created_at;
        _template.updatedAt = template.updated_at;
        _templates.push(_template);
      }

      userSMSTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
    }
  }

  static async loadPublicTemplates() {
    const _templates = [];
    try {
      await this.loadTemplates();

      for (const template of smsTemplates.templates) {
        if (template.private === false) {
          const _template = new SMSTemplate(template.name, template.message);
          _template.id = template.id;
          _template.private = template.private;
          _template.user = template.user;
          _template.folder = template.folder;
          _template.createdAt = template.created_at;
          _template.updatedAt = template.updated_at;
          _templates.push(_template);
        }
      }

      publicSMSTemplates.templates = _templates;
    } catch (error) {
      console.log(error);
    }
  }

  async createPublicTemplate(name) {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_API_URL}/templates`,
        {
          name,
          message: null,
          template_type: this.template_type,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateTemplateMessage() {
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_API_URL}/templates/${this.id}`,
        this.toJSON,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const _template = new SMSTemplate();
      for (const [key, value] of Object.entries(res.data)) {
        _template[key] = value;
      }
      return _template;
    } catch (error) {
      console.log(error);
    }
  }
}

export class TemplateFolder {
  constructor(name) {
    this.name = name;
  }

  set id(val) {
    this._id = val;
  }

  get id() {
    return this._id;
  }

  set templates(val) {
    this._templates = val;
  }

  get templates() {
    return this._templates;
  }

  async save() {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/template-folders`,
        { name: this.name },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async loadAllFolders() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/template-folders`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );

      const _folders = [];
      for (const folder of res.data) {
        const _folderTemplates = [];
        for (const template of folder.templates) {
          let _template;
          switch (template.template_type) {
            case "SMS":
              _template = new SMSTemplate(
                template.template_name,
                template.message
              );
              _template.id = template.id;
              _template.private = template.private;
              _template.created_at = template.created_at;
              _template.updated_at = template.updated_at;
              _template.user = template.user;
              break;
            case "EMAIL":
              _template = new EmailTemplate(
                template.template_name,
                template.message
              );
              _template.id = template.id;
              _template.private = template.private;
              _template.created_at = template.created_at;
              _template.updated_at = template.updated_at;
              _template.user = template.user;
              break;
          }
          _folderTemplates.push(_template);
        }
        const _folder = new TemplateFolder(folder.name);
        _folder.id = folder.id;
        _folder.templates = _folderTemplates;
        _folders.push(_folder);
      }
      templateFolders.folders = _folders;
    } catch (error) {
      console.log(error);
    }
  }
}

export const smsTemplates = reactive({
  templates: [],
  async loadTemplates() {
    await SMSTemplate.loadTemplates();
  },
});

export const userSMSTemplates = reactive({
  templates: [],
  async loadTemplates() {
    await SMSTemplate.loadUserTemplates();
  },
});

export const publicSMSTemplates = reactive({
  templates: [],
  async loadTemplates() {
    await SMSTemplate.loadPublicTemplates();
  },
});

export const emailTemplates = reactive({
  templates: [],
  async loadTemplates() {
    await EmailTemplate.loadTemplates();
  },
});

export const templateFolders = reactive({
  folders: [],
  async loadFolders() {
    await TemplateFolder.loadAllFolders();
  },
});

export const allTemplates = reactive({
  templates: [],
  async loadTemplates() {
    await Template.loadAllTemplates();
  },
});
