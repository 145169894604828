import axios from "axios";

export default {
  state() {
    return {
      showings: [],
    };
  },
  actions: {
    async loadShowings(context, payload) {
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL}/showings/${payload.showingDate}`,
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );

      context.commit("setShowings", { showings: res.data });
    },
    async checkIn(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/showings/check-in`,
          {
            showingId: payload.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        context.dispatch("loadShowings", {
          showingDate: new Date(payload.date),
        });
      } catch (error) {
        console.error(error.response.data.message);
      }
    },

    async noShow(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/showings/no-show`,
          {
            showingId: payload.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        context.dispatch("loadShowings", {
          showingDate: new Date(payload.date),
        });
      } catch (error) {
        console.error(error.response.data.message);
      }
    },

    async confirmShowing(context, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/showings/confirm/${payload.id}`,
          undefined,
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );

        context.dispatch("loadShowings", {
          showingDate: new Date(payload.date),
        });
      } catch (error) {
        console.error(error.response.data.message);
      }
    },

    async sendTexts(context, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/showings/send-texts`,
          {
            showingDate: payload.date,
            messageBody: payload.message,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.token}`,
            },
          }
        );
      } catch (error) {
        console.error(error.response.data.message);
      }
    },
  },
  mutations: {
    setShowings(state, payload) {
      state.showings = payload.showings;
    },
  },
  getters: {
    showings(state) {
      return state.showings;
    },
  },
};
