import { reactive } from "vue";
import axios from "axios";
import store from "../store/store";

export const userEventTypes = reactive([]);

export class Calendly {
  static _request = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/calendly`,
    headers: {
      Authorization: `Bearer ${store.getters.token}`,
    },
  });

  static async loadUserEventTypes() {
    try {
      const res = await this._request.get("/event_types", {
        params: {
          user: store.getters.userId,
        },
      });

      userEventTypes.value = res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  static async getEventTypeUnit(eventType) {
    try {
      const uri = new URL(eventType.scheduling_url);
      const path = uri.pathname;

      const res = await this._request.get("/unit", {
        params: {
          path,
        },
      });

      return res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }
}
