import axios from "axios";
import { reactive } from "vue";
import store from "../store/store";

export class Unit {
  id;
  name;
  bathrooms;
  bedrooms;
  custom_fields;
  sqft;
  listed_rent;
  status;
  property_id;
  current_occupancy_id;
  current_lease_id;
  description;
  address1;
  address2;
  city;
  state;
  zip;
  marketing_title;
  marketing_description;
  non_revenue;
  application_url;
  accepting_applications;
  pm_software_link;
  pm_softare_id;
  deposit;
  tags;
  application_fee;
  rent_ready;
  available_on;
  last_inspected_date;
  hidden_at;
  hidden;
  posted_to_website;
  posted_to_internet;
  days_vacant;
  geometry;
  lat;
  lng;
  image_url;
  showing_url;
  created_at;
  updated_at;
  property;
  owners;
  scorecards;
  current_lease;
  leases;

  async update() {
    try {
      const res = await axios.put(`${process.env.VUE_APP_API_URL}/units/${this.id}`, this, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
      return res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  static async load(unitId) {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/units/${unitId}`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      return new Unit().fill(res.data);
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  fill(unitObj) {
    for (const [key, value] of Object.entries(unitObj)) {
      this[key] = value;
    }

    return this;
  }

  async addImages(zipFile) {
    try {
      const formData = new FormData();
      formData.append("image_zip", zipFile, zipFile.name);
      await axios.post(`${process.env.VUE_APP_API_URL}/images/unit/${this.appfolio_id}`, formData, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async loadImages() {
    try {
      const s3 = await axios.get(`${process.env.VUE_APP_CDN_URL}/?list-type=2&prefix=images/unit/${this.appfolio_id}`);
      const parser = new DOMParser();
      const doc = parser.parseFromString(s3.data, "application/xml");
      const results = doc.querySelectorAll("Contents");
      const images = [];
      for (let i = 0; i < results.length; i++) {
        const size = results[i].querySelector("Size").innerHTML;
        if (size > 0) {
          let obj = {};
          const key = results[i].querySelector("Key").innerHTML;
          const regex = new RegExp(`(?<=${this.appfolio_id}\\/)(\\d+)(?=_)`, "g");
          let index = key.match(regex);
          obj.key = key;
          obj.src = `${process.env.VUE_APP_CDN_URL}/${key}`;
          try {
            const res = await axios.get(`${process.env.VUE_APP_CDN_URL}/${key}?tagging`, {
              headers: {
                "Allow-Control-Allow-Origin": "*",
              },
            });
            const doc = parser.parseFromString(res.data, "application/xml");
            const tags = doc.querySelectorAll("Tag");
            for (let ii = 0; ii < tags.length; ii++) {
              const tagKey = tags[ii].querySelector("Key");
              if (tagKey.innerHTML === "alt") {
                const alt = tags[ii].querySelector("Value").innerHTML;
                obj.alt = alt;
              }
            }
          } catch (error) {
            console.log(error);
          }
          if (index) {
            images.splice(index[0], 0, obj);
          } else {
            images.push(obj);
          }
        }
      }
      return images;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async saveImageOrder(keys) {
    try {
      await axios.put(
        `${process.env.VUE_APP_API_URL}/images/unit/${this.appfolio_id}`,
        { keys },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  async deleteImage(key) {
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/images/unit/${this.appfolio_id}?key=${key}`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }

  static async getSchema() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/schema/unit`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      const schema = [];

      for (const value of Object.values(res.data)) {
        if (value.meta && value.meta.columnLabel) {
          schema.push(value);
        }
      }

      return schema;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  }
}

export const mapUnits = reactive({
  units: [],
  async loadUnits() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/maps/units`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });
      this.units = res.data;
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  },
});

export const showingUnits = reactive({
  units: [],
  async loadUnits() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/units?has_showings=1`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      this.units = res.data.map((unit) => {
        const u = new Unit();
        u.fill(unit);
        return u;
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  },
});

export const scorecardUnits = reactive({
  data: [],
  async loadUnits() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/scorecards/units`, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      });

      this.data = res.data.map((unit) => {
        const u = new Unit();
        u.fill(unit);
        return u;
      });
    } catch (error) {
      if (error.isAxiosError) {
        console.log(error.response);
      }
      throw error;
    }
  },
});
